import { BsCalendarCheck, BsCalendarEvent, BsFileEarmarkRichtextFill, BsList } from "react-icons/bs";
import { FaCheckSquare, FaIdCard, FaKey, FaLink, FaLock, FaMoneyBill, FaPhoneAlt, FaTable } from "react-icons/fa";
import Formula, { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../Components/Formula";
import { IoMdPersonAdd, IoMdRadioButtonOn } from "react-icons/io";
import { MdAttachFile, MdChecklist, MdDashboardCustomize, MdShortText, MdTitle, MdTextFields } from "react-icons/md";
import React, { InputHTMLAttributes } from "react";
import { TiFlowMerge, TiSortNumerically } from "react-icons/ti";

import { AiOutlineDash } from "react-icons/ai";
import { BiChevronDownSquare } from "react-icons/bi";
import { BiText } from "react-icons/bi";
import CheckBox from "../Components/CheckBox";
import CheckBoxOne from "../Components/CheckBoxOne";
import ColorPicker from "../Components/ColorPicker";
import ComboBox from "../Components/ComboBox";
import ComboBoxFlow from "../Components/ComboBoxFlow";
import ComboBoxRegister from "../Components/ComboBoxRegister";
import ComboBoxUser from "../Components/ComboBoxUser";
import DatePicker from "../Components/DatePicker";
import DescriptionComponent from "../Components/DescriptionField";
import DividerComponent from "../Components/DividerField";
import { FaRegImage } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import { FiCalendar } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { Flow } from "../../../../interfaces/Flow";
import { GiPaintBucket } from "react-icons/gi";
import { IconBaseProps } from "react-icons";
import IconPicker from "../Components/IconPicker";
import Input from "../Components/Input";
import InputAttach from "../Components/InputAttach";
import InputCurrency from "../Components/InputCurrency";
import InputDoc from "../Components/InputDoc";
import InputItemList from "../Components/InputItemList";
import InputNumber from "../Components/InputNumber";
import InputPhone from "../Components/InputPhone";
import { IoMdSwitch } from "react-icons/io";
import RadioBox from "../Components/RadioBox";
import { Register } from "../../../../interfaces/Register";
import Switch from "../Components/Switch";
import { TbMathFunction } from "react-icons/tb";
import TextAreaFormula from "../Components/TextAreaFormula";
import TextAreaInput from "../Components/TextAreaInput";
import TitleComponent from "../Components/TitleField";
import { FormAnswer } from "../../../../interfaces/FormAnswer";
import SlaConfigurator from "../Components/SlaConfigurator";
import InputRichText from "../Components/InputRichText";
import * as Yup from 'yup';
import { FieldMapAc } from "../../../../interfaces/FieldMapAc";
import CustomSelect from "../Components/CustomSelect";
import CheckList from "../Components/CheckListField";
import { Step } from "../../../../interfaces/Step";
import DynamicText from "../Components/DynamicText";
import Id from "../Components/Id";
import Link from "../Components/Link";
import { Card } from "../../../../interfaces/Card";
import { Conditional } from "../../../../interfaces/Conditional";
import { isDate, parse } from "date-fns";

export interface FieldOptionsProps {
  id_field_option?: number;
  field_id?: number;
  value: string;
  label: string;
  order?: string;
  hide?: string;
  old_id_field_option?: number;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconDynamic?: string;
  description?: string;
  type?: string;
  group?: string;
  groupIndex?: number;
  flow_id?: number;
  register_id?: number;
}

export interface TypeFieldProps {
  id: string;
  name: string;
  index: number;
  icon: React.ComponentType<IconBaseProps>;
  color?: string;
  color_background?: string;
  isBeta?: boolean;
  isNew?: boolean;
  help_text?: string;
}

export interface ValidationFieldProps {
  id_field_validation?: number;
  type: string;
  params: (string | number)[];
}

export interface FieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id_field?: number;
  name: string;
  type: string;
  variation?: string;
  title: string;
  description: string;
  index: number;
  placeholder?: string;
  help_text?: string;
  required?: boolean;
  validation_type?: string;
  validations?: ValidationFieldProps[];
  turn_on_field?: boolean;
  on_field?: boolean;
  formula?: string;
  updated?: string; //If is possible to update the field
  show_on_form?: string; //If is possible to show on form
  ac_type?: number;
  ac_parent_field_id?: number;
  ac_child_field_id?: number;
  ac_form_answer_id?: number;
  options?: FieldOptionsProps[];
  max_length?: number;
  company_id?: number;
  register_id?: number;
  flow_id?: number;
  filter_schema?: string;
  form_id?: number;
  user_id_creator?: number;
  unique?: string;
  card_title?: string;
  card_highlight?: string;
  card_aggregate?: string;
  inline_picker?: boolean;
  on_edit?: boolean;
  currentEdit?: boolean;
  register?: Register;
  flow?: Flow;
  formFields?: FieldProps[];
  conditionals?: Conditional[];
  auto_complete?: FormAnswer;
  previewField?: boolean; //When is add a new field
  flow_base_id?: number; //The flow that the fields belongs
  register_base_id?: number; //The register that the fields belongs
  card_current_id?: number; //The card that the fields belongs
  card_current?: Card;
  field_children?: FieldProps[];
  map_ac?: FieldMapAc[];
  flow_step?: Step;
  group?: string;
  groupIndex?: number;
  isPublicForm?: boolean;
  public_hash?: string;
  updatedByConditional?: boolean;
  propsBase?: {
    show_on_form?: string;
    required?: boolean;
    validations?: ValidationFieldProps[];
  },
  isFromAutomation?: boolean;
  onForceBlur?: () => void;
  //Quando adicionar aqui é necessário atualizar no FieldContainer (Linha 68)
}

/* Components */
const IconPickerField: React.FC<FieldProps> = ({ name, onForceBlur }) => {
  return (<IconPicker name={name} onForceBlur={onForceBlur} />);
};

const ColorPickerField: React.FC<FieldProps> = ({ name, variation, onForceBlur }) => {
  return (<ColorPicker name={name} variation={variation} onForceBlur={onForceBlur} />);
};

const RadioBoxField: React.FC<FieldProps> = ({ name, options, onForceBlur }) => {
  return <RadioBox name={name} options={options} onForceBlur={onForceBlur} />;
};

const DatePickerField: React.FC<FieldProps> = ({ name, placeholder, inline_picker, title, variation, onForceBlur }) => {
  return <DatePicker name={name} icon={FiCalendar} placeholder={placeholder} onInLine={inline_picker} title={title} variation={variation} onForceBlur={onForceBlur} />;
};

const DueDateField: React.FC<FieldProps> = ({ name, placeholder, inline_picker, title, variation, onForceBlur }) => {
  return <DatePicker name={name} icon={FiCalendar} placeholder={placeholder} onInLine={inline_picker} title={title} variation={variation} onForceBlur={onForceBlur} />;
};

const CheckBoxField: React.FC<FieldProps> = ({ name, options, onForceBlur }) => {
  return <CheckBox name={name} options={options} onForceBlur={onForceBlur} />;
};

const CheckBoxOneField: React.FC<FieldProps> = ({ name, placeholder }) => {
  return <CheckBoxOne name={name} placeHolder={placeholder} />;
};

const TextAreaInputField: React.FC<FieldProps> = ({ name, placeholder }) => {
  return <TextAreaInput name={name} placeholder={placeholder} icon={BiText} />;
};

const MailField: React.FC<FieldProps> = ({ name, placeholder, max_length }) => {
  return <Input name={name} placeholder={placeholder} icon={FiMail} max_length={max_length} />;
};

const TextShortField: React.FC<FieldProps> = ({ name, placeholder, max_length, variation, formula }) => {
  return <Input name={name} placeholder={placeholder} icon={FiAlignJustify} max_length={max_length} variation={variation} formula={formula} />
};

const PasswordField: React.FC<FieldProps> = ({ name, placeholder, max_length }) => {
  return <Input name={name} placeholder={placeholder} icon={FaLock} max_length={max_length} type="password" />
};

const CurrencyField: React.FC<FieldProps> = ({ name, placeholder, max_length }) => {
  return <InputCurrency name={name} placeholder={placeholder} icon={FaMoneyBill} max_length={max_length} />
};

const NumberField: React.FC<FieldProps> = ({ name, placeholder, max_length }) => {
  return <InputNumber name={name} placeholder={placeholder} icon={TiSortNumerically} max_length={max_length} />
};

const PhoneField: React.FC<FieldProps> = ({ name, placeholder }) => {
  return <InputPhone name={name} placeholder={placeholder} icon={FaPhoneAlt} />
};

const DocField: React.FC<FieldProps> = ({ name, placeholder, variation }) => {
  return <InputDoc name={name} placeholder={placeholder} icon={FaIdCard} variation={variation} />
};

const SwitchField: React.FC<FieldProps> = ({ name }) => {
  return <Switch name={name} />;
};

const ComboBoxField: React.FC<FieldProps> = ({ name, options, placeholder, onForceBlur }) => {
  return <ComboBox name={name} options={options} placeholder={placeholder} onForceBlur={onForceBlur} />;
};

const ComboBoxUserField: React.FC<FieldProps> = ({ name, options, placeholder, form_id, variation, isPublicForm, public_hash, register_base_id }) => {
  return <ComboBoxUser name={name} options={options} placeholder={placeholder} form_id={form_id} variation={variation} isPublicForm={isPublicForm} public_hash={public_hash} register_base_id={register_base_id} />;
};

const ComboBoxRegisterField: React.FC<FieldProps> = ({ name, register_id, placeholder, options, variation, max_length, previewField, card_current_id, flow_base_id, register_base_id, onForceBlur, isPublicForm, public_hash, filter_schema }) => {
  return <ComboBoxRegister name={name} variation={variation} register_id={register_id} placeholder={placeholder} options={options} max_length={max_length} previewField={previewField} card_current_id={card_current_id} flow_base_id={flow_base_id} register_base_id={register_base_id} onForceBlur={onForceBlur} isPublicForm={isPublicForm} public_hash={public_hash} filter_schema={filter_schema} />;
};

const ComboBoxFlowField: React.FC<FieldProps> = ({ name, flow_id, placeholder, options, variation, max_length, previewField, flow_base_id, card_current_id, onForceBlur, isPublicForm, public_hash, filter_schema, isFromAutomation }) => {
  return <ComboBoxFlow name={name} variation={variation} flow_id={flow_id} placeholder={placeholder} options={options} max_length={max_length} previewField={previewField} flow_base_id={flow_base_id} card_current_id={card_current_id} onForceBlur={onForceBlur} isPublicForm={isPublicForm} public_hash={public_hash} filter_schema={filter_schema} isFromAutomation={isFromAutomation} />;
};

const InputListField: React.FC<FieldProps> = ({ name, max_length }) => {
  return <InputItemList name={name} max_length={max_length} />;
};

const TitleField: React.FC<FieldProps> = ({ title }) => {
  return <TitleComponent title={title} />;
};

const DescriptionField: React.FC<FieldProps> = ({ title }) => {
  return <DescriptionComponent title={title} />;
};

const DividerField: React.FC<FieldProps> = () => {
  return <DividerComponent />;
};

const InputAttachField: React.FC<FieldProps> = ({ name, placeholder, max_length, isPublicForm, public_hash, formula, variation, card_current_id, flow_base_id }) => {
  return <InputAttach name={name} placeholder={placeholder} max_length={max_length} isPublicForm={isPublicForm} public_hash={public_hash} formula={formula} variation={variation} card_current_id={card_current_id} flow_id={flow_base_id} />;
};

const FormulaField: React.FC<FieldProps> = ({ name, formula, description, variation }) => {
  return <Formula name={name} formula={formula} description={description} variation={variation} />;
};

const TextAreaFormulaField: React.FC<FieldProps> = ({ name, placeholder, formFields }) => {
  return <TextAreaFormula name={name} placeholder={placeholder} icon={TbMathFunction} formFields={formFields} />;
};

const SlaConfiguratorField: React.FC<FieldProps> = ({ name, placeholder, flow_base_id }) => {
  return <SlaConfigurator name={name} placeholder={placeholder} flow_id={flow_base_id} />;
};

const InputRichTextField: React.FC<FieldProps> = ({ name, placeholder }) => {
  return <InputRichText name={name} placeholder={placeholder} />;
};

const CustomSelectField: React.FC<FieldProps> = ({ name, description, variation, options, placeholder, max_length, onForceBlur }) => {
  return <CustomSelect name={name} description={description} variation={variation} options={options} placeholder={placeholder} max_length={max_length} onForceBlur={onForceBlur} />;
}

const CheckListField: React.FC<FieldProps> = ({ name, variation, onForceBlur }) => {
  return <CheckList name={name} variation={variation} onForceBlur={onForceBlur} />;
};

const DynamicTextField: React.FC<FieldProps> = ({ name, formula, card_current }) => {
  return <DynamicText name={name} formula={formula} card_current={card_current} />;
};

const IdField: React.FC<FieldProps> = ({ name }) => {
  return <Id name={name} />;
};

const LinkField: React.FC<FieldProps> = ({ name }) => {
  return <Link name={name} />;
};

/* Relationship */
export const Fields = {
  TEXT_SHORT_FIELD: "TEXT_SHORT_FIELD",
  TEXT_LONG_FIELD: "TEXT_LONG_FIELD",
  MAIL_FIELD: "MAIL_FIELD",
  COMBO_BOX_FIELD: "COMBO_BOX_FIELD",
  RADIO_BOX_FIELD: "RADIO_BOX_FIELD",
  CHECK_BOX_FIELD: "CHECK_BOX_FIELD",
  DATE_PICKER_FIELD: "DATE_PICKER_FIELD",
  SWITCH_FIELD: "SWITCH_FIELD",
  INPUT_LIST_FIELD: "INPUT_LIST_FIELD",
  COLOR_PICKER_FIELD: "COLOR_PICKER_FIELD",
  ICON_PICKER_FIELD: "ICON_PICKER_FIELD",
  CHECK_BOX_ONE_FIELD: "CHECK_BOX_ONE_FIELD",
  COMBO_BOX_USER_FIELD: "COMBO_BOX_USER_FIELD",
  TITLE_FIELD: "TITLE_FIELD",
  DESCRIPTION_FIELD: "DESCRIPTION_FIELD",
  DIVIDER_FIELD: "DIVIDER_FIELD",
  DUE_DATE_FIELD: "DUE_DATE_FIELD",
  CURRENCY_FIELD: "CURRENCY_FIELD",
  NUMBER_FIELD: "NUMBER_FIELD",
  PASSWORD_FIELD: "PASSWORD_FIELD",
  PHONE_FIELD: "PHONE_FIELD",
  DOC_FIELD: "DOC_FIELD",
  COMBO_BOX_REGISTER_FIELD: "COMBO_BOX_REGISTER_FIELD",
  COMBO_BOX_FLOW_FIELD: "COMBO_BOX_FLOW_FIELD",
  INPUT_ATTACH_FIELD: "INPUT_ATTACH_FIELD",
  FORMULA_FIELD: "FORMULA_FIELD",
  TEXT_FORMULA_FIELD: "TEXT_FORMULA_FIELD",
  SLA_CONFIGURATOR_FIELD: "SLA_CONFIGURATOR_FIELD",
  INPUT_RICH_TEXT_FIELD: "INPUT_RICH_TEXT_FIELD",
  CUSTOM_SELECT_FIELD: "CUSTOM_SELECT_FIELD",
  CHECK_LIST_FIELD: "CHECK_LIST_FIELD",
  DYNAMIC_TEXT_FIELD: "DYNAMIC_TEXT_FIELD",
  ID_FIELD: "ID_FIELD",
  LINK_FIELD: "LINK_FIELD",
};

export interface FieldTypeProps {
  type: string; //Field type - Ex: TEXT_SHORT_FIELD
  icon: React.ComponentType<IconBaseProps>;
  title: string;
  color: string;
  colorBackground: string;
  validationType: string;
  component: React.FC;
  onFilter: boolean; // If is avaible on filter
  comparationFilter: string[];
  onSorter: boolean;
  orderSorter: string[];
  onAgregateField: boolean; //If is avaible on axis y to agregate with sum/max/etc
  onAddNewField: boolean; //If is avaible on pick new field
  onShowInAnswerRecord: boolean; //If will show in the record already saved
  onAutoComplete: boolean; //If will show in the auto complete
  onImporterData: boolean; //If will show in the importer data
  onViewFlow?: boolean; //If will show in any view flow
  index?: number;
}

/* Object Field FieldTypeProps */
export const getFieldObject = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: {
      type: "TEXT_SHORT_FIELD",
      icon: FiAlignJustify,
      title: "Texto curto",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: TextShortField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 1
    },
    [Fields.TEXT_LONG_FIELD]: {
      type: "TEXT_LONG_FIELD",
      icon: BiText,
      title: "Texto longo",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: TextAreaInputField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 2
    },
    [Fields.MAIL_FIELD]: {
      type: "MAIL_FIELD",
      icon: FiMail,
      title: "E-mail",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: MailField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 12
    },
    [Fields.COMBO_BOX_FIELD]: {
      type: "COMBO_BOX_FIELD",
      icon: BiChevronDownSquare,
      title: "Caixa de seleção",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "string",
      component: ComboBoxField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "Primeiro → Último",
        "Último → Primeiro"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 3
    },
    [Fields.RADIO_BOX_FIELD]: {
      type: "RADIO_BOX_FIELD",
      icon: IoMdRadioButtonOn,
      title: "Seleção única",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "string",
      component: RadioBoxField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "Primeiro → Último",
        "Último → Primeiro"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 5
    },
    [Fields.CHECK_BOX_FIELD]: {
      type: "CHECK_BOX_FIELD",
      icon: FaCheckSquare,
      title: "Seleção múltipla",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "array",
      component: CheckBoxField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "Primeiro → Último",
        "Último → Primeiro"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 6
    },
    [Fields.DATE_PICKER_FIELD]: {
      type: "DATE_PICKER_FIELD",
      icon: BsCalendarEvent,
      title: "Data",
      color: "#4680B8",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: DatePickerField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Antes de",
        "Depois de",
        "Igual a ou depois",
        "Igual a ou antes",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "1 → 9",
        "9 → 1"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 4
    },
    [Fields.SWITCH_FIELD]: {
      type: "SWITCH_FIELD",
      icon: IoMdSwitch,
      title: "Interruptor",
      color: "#ff8c2f",
      colorBackground: "#ff8c2f1a",
      validationType: "boolean",
      component: SwitchField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
      ],
      onSorter: true,
      orderSorter: [
        "Verdadeiro → Falso",
        "Falso → Verdadeiro"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: true,
      onViewFlow: true,
      index: 14
    },
    [Fields.INPUT_LIST_FIELD]: {
      type: "INPUT_LIST_FIELD",
      icon: BsList,
      title: "Lista de itens",
      color: "#61bd4f",
      colorBackground: "#61bd4f1a",
      validationType: "array",
      component: InputListField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "Primeiro → Último",
        "Último → Primeiro"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 15
    },
    [Fields.COLOR_PICKER_FIELD]: {
      type: "COLOR_PICKER_FIELD",
      icon: GiPaintBucket,
      title: "Cor",
      color: "#4680B8",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: ColorPickerField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 21
    },
    [Fields.ICON_PICKER_FIELD]: {
      type: "ICON_PICKER_FIELD",
      icon: FaRegImage,
      title: "Ícone",
      color: "#4680B8",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: IconPickerField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 22
    },
    [Fields.CHECK_BOX_ONE_FIELD]: {
      type: "CHECK_BOX_ONE_FIELD",
      icon: FaCheckSquare,
      title: "Seleção única box",
      color: "#ff8c2f",
      colorBackground: "#ff8c2f1a",
      validationType: "string",
      component: CheckBoxOneField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "Primeiro → Último",
        "Último → Primeiro"
      ],
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 23
    },
    [Fields.COMBO_BOX_USER_FIELD]: {
      type: "COMBO_BOX_USER_FIELD",
      icon: IoMdPersonAdd,
      title: "Responsável",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "string",
      component: ComboBoxUserField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 7
    },
    [Fields.TITLE_FIELD]: {
      type: "TITLE_FIELD",
      icon: MdTitle,
      title: "Título",
      color: "#00bcd4",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: TitleField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 18
    },
    [Fields.DESCRIPTION_FIELD]: {
      type: "DESCRIPTION_FIELD",
      icon: MdShortText,
      title: "Descrição",
      color: "#00bcd4",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: DescriptionField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 19
    },
    [Fields.DIVIDER_FIELD]: {
      type: "DIVIDER_FIELD",
      icon: AiOutlineDash,
      title: "Divisor",
      color: "#00bcd4",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: DividerField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 20
    },
    [Fields.DUE_DATE_FIELD]: {
      type: "DUE_DATE_FIELD",
      icon: BsCalendarCheck,
      title: "Vencimento",
      color: "#4680B8",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: DueDateField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Antes de",
        "Depois de",
        "Igual a ou depois",
        "Igual a ou antes",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "1 → 9",
        "9 → 1"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 11
    },
    [Fields.CURRENCY_FIELD]: {
      type: "CURRENCY_FIELD",
      icon: FaMoneyBill,
      title: "Moeda",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "number",
      component: CurrencyField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Menor que",
        "Maior que",
        "Igual a ou maior que",
        "Igual a ou menor que",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "1 → 9",
        "9 → 1"
      ],
      onAgregateField: true, //true
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 10
    },
    [Fields.NUMBER_FIELD]: {
      type: "NUMBER_FIELD",
      icon: TiSortNumerically,
      title: "Numérico",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "number",
      component: NumberField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Menor que",
        "Maior que",
        "Igual a ou maior que",
        "Igual a ou menor que",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "1 → 9",
        "9 → 1"
      ],
      onAgregateField: true, //true
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 16
    },
    [Fields.PASSWORD_FIELD]: {
      type: "PASSWORD_FIELD",
      icon: FaLock,
      title: "Senha",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: PasswordField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 24
    },
    [Fields.PHONE_FIELD]: {
      type: "PHONE_FIELD",
      icon: FaPhoneAlt,
      title: "Telefone",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: PhoneField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 13
    },
    [Fields.DOC_FIELD]: {
      type: "DOC_FIELD",
      icon: FaIdCard,
      title: "Documentos",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: DocField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 17
    },
    [Fields.COMBO_BOX_REGISTER_FIELD]: {
      type: "COMBO_BOX_REGISTER_FIELD",
      icon: FaTable,
      title: "Meus Cadastros",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "string",
      component: ComboBoxRegisterField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 8
    },
    [Fields.COMBO_BOX_FLOW_FIELD]: {
      type: "COMBO_BOX_FLOW_FIELD",
      icon: TiFlowMerge,
      title: "Meus Fluxos",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "string",
      component: ComboBoxFlowField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Não é igual a",
        "É uma das",
        "Não é uma das",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: true,
      index: 9
    },
    [Fields.INPUT_ATTACH_FIELD]: {
      type: "INPUT_ATTACH_FIELD",
      icon: MdAttachFile,
      title: "Anexo",
      color: "#9e37ed",
      colorBackground: "#9e37ed1a",
      validationType: "mixed",
      component: InputAttachField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 7
    },
    [Fields.FORMULA_FIELD]: {
      type: "FORMULA_FIELD",
      icon: TbMathFunction,
      title: "Fórmulas",
      color: "#4caf50",
      colorBackground: "#4caf501a",
      validationType: "string",
      component: FormulaField,
      onFilter: true,
      comparationFilter: [
        "Igual a",
        "Menor que",
        "Maior que",
        "Igual a ou maior que",
        "Igual a ou menor que",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "1 → 9",
        "9 → 1"
      ],
      onAgregateField: true,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: true,
      index: 25
    },
    [Fields.TEXT_FORMULA_FIELD]: {
      type: "TEXT_FORMULA_FIELD",
      icon: TbMathFunction,
      title: "Editor de Fórmulas",
      color: "#4caf50",
      colorBackground: "#4caf501a",
      validationType: "string",
      component: TextAreaFormulaField,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 26
    },
    [Fields.SLA_CONFIGURATOR_FIELD]: {
      type: "SLA_CONFIGURATOR_FIELD",
      icon: TbMathFunction,
      title: "Configurador de SLA",
      color: "#4caf50",
      colorBackground: "#4caf501a",
      validationType: "object",
      component: SlaConfigurator,
      onFilter: false,
      onSorter: false,
      comparationFilter: undefined,
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 27
    },
    [Fields.INPUT_RICH_TEXT_FIELD]: {
      type: "INPUT_RICH_TEXT_FIELD",
      icon: BsFileEarmarkRichtextFill,
      title: "Texto Formatado",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: InputRichText,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 28
    },
    [Fields.CUSTOM_SELECT_FIELD]: {
      type: "CUSTOM_SELECT_FIELD",
      icon: MdDashboardCustomize,
      title: "Campo Customizado",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: CustomSelectField,
      onFilter: false,
      onSorter: false,
      onAgregateField: false,
      onAddNewField: false,
      onShowInAnswerRecord: false,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 29
    },
    [Fields.CHECK_LIST_FIELD]: {
      type: "CHECK_LIST_FIELD",
      icon: MdChecklist,
      title: "Check List",
      color: "#61bd4f",
      colorBackground: "#61bd4f1a",
      validationType: "array",
      component: CheckListField,
      onFilter: false,
      onSorter: false,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: false,
      onViewFlow: true,
      index: 30
    },
    [Fields.DYNAMIC_TEXT_FIELD]: {
      type: "DYNAMIC_TEXT_FIELD",
      icon: MdTextFields,
      title: "Conteúdo Dinâmico",
      color: "#00bcd4",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: DynamicTextField,
      onFilter: false,
      onSorter: false,
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: false,
      index: 31
    },
    [Fields.ID_FIELD]: {
      type: "ID_FIELD",
      icon: FaKey,
      title: "ID",
      color: "#00bcd4",
      colorBackground: "#4680B81a",
      validationType: "string",
      component: IdField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: false,
      onImporterData: false,
      onViewFlow: true,
      index: 32
    },
    [Fields.LINK_FIELD]: {
      type: "LINK_FIELD",
      icon: FaLink,
      title: "Link",
      color: "#f23b5c",
      colorBackground: "#f23b5c1a",
      validationType: "string",
      component: LinkField,
      onFilter: true,
      comparationFilter: [
        "Contém",
        "Não contém",
        "Igual a",
        "Não é igual a",
        "Está em branco",
        "Não está em branco"
      ],
      onSorter: true,
      orderSorter: [
        "A → Z",
        "Z → A"
      ],
      onAgregateField: false,
      onAddNewField: true,
      onShowInAnswerRecord: true,
      onAutoComplete: true,
      onImporterData: true,
      onViewFlow: true,
      index: 33
    },
  };

  return relationFields[type];
};

/* Relationship */
//When add a new field must add in FilterDialog (Line 122) too
export const FieldsIcons = {
  TEXT_SHORT_FIELD: FiAlignJustify,
  TEXT_LONG_FIELD: BiText,
  MAIL_FIELD: FiMail,
  COMBO_BOX_FIELD: BiChevronDownSquare,
  RADIO_BOX_FIELD: IoMdRadioButtonOn,
  CHECK_BOX_FIELD: FaCheckSquare,
  DATE_PICKER_FIELD: BsCalendarEvent,
  SWITCH_FIELD: IoMdSwitch,
  INPUT_LIST_FIELD: BsList,
  COLOR_PICKER_FIELD: GiPaintBucket,
  ICON_PICKER_FIELD: FaRegImage,
  CHECK_BOX_ONE_FIELD: FaCheckSquare,
  COMBO_BOX_USER_FIELD: IoMdPersonAdd,
  TITLE_FIELD: MdTitle,
  DESCRIPTION_FIELD: MdShortText,
  DIVIDER_FIELD: AiOutlineDash,
  DUE_DATE_FIELD: BsCalendarCheck,
  CURRENCY_FIELD: FaMoneyBill,
  NUMBER_FIELD: TiSortNumerically,
  PASSWORD_FIELD: FaLock,
  PHONE_FIELD: FaPhoneAlt,
  DOC_FIELD: FaIdCard,
  COMBO_BOX_REGISTER_FIELD: FaTable,
  COMBO_BOX_FLOW_FIELD: TiFlowMerge,
  INPUT_ATTACH_FIELD: MdAttachFile,
  FORMULA_FIELD: TbMathFunction,
  TEXT_FORMULA_FIELD: TbMathFunction,
  SLA_CONFIGURATOR_FIELD: TbMathFunction,
  INPUT_RICH_TEXT_FIELD: BsFileEarmarkRichtextFill,
  CUSTOM_SELECT_FIELD: MdDashboardCustomize,
  CHECK_LIST_FIELD: MdChecklist,
  DYNAMIC_TEXT_FIELD: MdTextFields,
  ID_FIELD: FaKey,
  LINK_FIELD: FaLink,
};

/* Relationship */
export const FieldsTypes = {
  TEXT_SHORT_FIELD: "Texto curto",
  TEXT_LONG_FIELD: "Texto longo",
  MAIL_FIELD: "E-mail",
  COMBO_BOX_FIELD: "Caixa de seleção",
  RADIO_BOX_FIELD: "Seleção única",
  CHECK_BOX_FIELD: "Seleção múltipla",
  DATE_PICKER_FIELD: "Data",
  SWITCH_FIELD: "Interruptor",
  INPUT_LIST_FIELD: "Lista de itens",
  COLOR_PICKER_FIELD: "Cor",
  ICON_PICKER_FIELD: "Ícone",
  CHECK_BOX_ONE_FIELD: "Seleção única box",
  COMBO_BOX_USER_FIELD: "Responsável",
  TITLE_FIELD: "Título",
  DESCRIPTION_FIELD: "Descrição",
  DIVIDER_FIELD: "Divisor",
  DUE_DATE_FIELD: "Vencimento",
  CURRENCY_FIELD: "Moeda",
  NUMBER_FIELD: "Numérico",
  PASSWORD_FIELD: "Senha",
  PHONE_FIELD: "Telefone",
  DOC_FIELD: "Documentos",
  COMBO_BOX_REGISTER_FIELD: "Meus Cadastros",
  COMBO_BOX_FLOW_FIELD: "Meus Fluxos",
  INPUT_ATTACH_FIELD: "Anexo",
  FORMULA_FIELD: "Fórmula",
  TEXT_FORMULA_FIELD: "Editor de Fórmulas",
  SLA_CONFIGURATOR_FIELD: "Configurador de SLA",
  INPUT_RICH_TEXT_FIELD: "Texto Formatado",
  CUSTOM_SELECT_FIELD: "Campo Customizado",
  CHECK_LIST_FIELD: "Check List",
  DYNAMIC_TEXT_FIELD: "Conteúdo Dinâmico",
  ID_FIELD: "ID",
  LINK_FIELD: "Link",
};

export interface ErrorFieldValidation {
  type: "Quotes" | "Delimiter" | "FieldMismatch" | 'required' | 'typeMismatch' | 'valueNotFound';
  message: string;
  row?: number;
}

export async function validateField(field: FieldProps, value: unknown, row?: number): Promise<ErrorFieldValidation[]> {

  let errors: ErrorFieldValidation[] = [];
  let newValue: string = "";

  //Valid if the field is parsable to string
  if (value !== undefined && value !== null && typeof value !== 'string') {
    errors.push({
      type: 'typeMismatch',
      message: field.title + ": O valor enviado não é válido para o campo",
      row: row
    });
  } else {
    newValue = String(value);
  }

  //Valid if the field is required (1 or 0) and if the value is empty
  if (field.required !== undefined && field.required && (value === undefined || value === null || value === '')) {
    errors.push({
      type: 'required',
      message: field.title + ": O campo é obrigatório e não foi possível ler o valor",
      row: row
    });
  }

  //Valid the type of the field
  try {
    let schema: Yup.StringSchema<string | undefined | null> | Yup.BooleanSchema<boolean | undefined | null, object> | Yup.DateSchema<Date | undefined | null> | Yup.NumberSchema<number | undefined | null> | Yup.MixedSchema<unknown, object>;
    schema = Yup.string();

    switch (field.type) {
      case 'TEXT_SHORT_FIELD':
        schema = Yup.string().max(250, 'Texto muito longo para o campo de Texto Curto');
        break;

      case 'TEXT_LONG_FIELD':
      case 'INPUT_RICH_TEXT_FIELD':
      case 'LINK_FIELD':
        schema = Yup.string();
        break;

      case 'MAIL_FIELD':
        schema = Yup.string().email('O e-mail náo é válido');
        break;

      case 'COMBO_BOX_FIELD':
      case 'RADIO_BOX_FIELD':

        let isBlank = false;
        if (field.required !== undefined && field.required === false && (value === undefined || value === null || value === '')) {
          isBlank = true;
        }

        if (field.options !== undefined && field.options !== null && field.options.length > 0 && !isBlank) {

          const validExist = field.options?.filter((option) => option.label === newValue);

          if (validExist === undefined || validExist === null || validExist.length === 0) {
            errors.push({
              type: 'valueNotFound',
              message: field.title + ": O valor enviado não existe no campo de seleção",
              row: row
            });
          }

        } else if (!isBlank) {

          errors.push({
            type: 'valueNotFound',
            message: field.title + ": O valor enviado não existe no campo de seleção",
            row: row
          });

        }

        break;

      case 'CHECK_BOX_FIELD':

        let isBlankCheck = false;
        if (field.required !== undefined && field.required === false && (value === undefined || value === null || value === '')) {
          isBlankCheck = true;
        }

        if (field.options !== undefined && field.options !== null && field.options.length > 0 && !isBlankCheck) {

          //Valid if has more than one option in the valeu, divider is ;
          const values = newValue.split(';');

          values.forEach((value) => {

            const validExist = field.options?.filter((option) => option.label === value);

            if (validExist === undefined || validExist === null || validExist.length === 0) {
              errors.push({
                type: 'valueNotFound',
                message: field.title + ": O valor enviado não existe no campo de seleção",
                row: row
              });
            }

          });

        } else if (!isBlankCheck) {

          errors.push({
            type: 'valueNotFound',
            message: field.title + ": O valor enviado não existe no campo de seleção",
            row: row
          });

        }

        break;

      case 'DATE_PICKER_FIELD':
      case 'DUE_DATE_FIELD':

        if ((field.required === undefined || field.required === null || Boolean(field.required) === false) && (value === undefined || value === null || value === '')) {

        } else {
          const parseDateString = (value: string, originalValue: string) => {
            if (isDate(originalValue)) {
              return originalValue;
            }

            const formats = ['dd/MM/yyyy', 'dd/MM/yyyy HH:mm'];
            let parsedDate = null;

            for (let format of formats) {
              const date = parse(originalValue, format, new Date());
              if (isDate(date) && !isNaN(date.getTime())) {
                parsedDate = date;
                break;
              }
            }

            return parsedDate;
          };

          schema = Yup.date().transform(parseDateString);
        }

        break;

      case 'SWITCH_FIELD':

        if (newValue !== undefined && newValue !== null && newValue !== '') {
          if (newValue !== 'true' && newValue !== 'false' && newValue !== '0' && newValue !== '1' && newValue !== 'N' && newValue !== 'S') {
            errors.push({
              type: 'typeMismatch',
              message: field.title + ": O valor enviado não é válido para o campo de interruptor",
              row: row
            });
          }
        }

        break;

      case 'COMBO_BOX_USER_FIELD':
        // Adapte conforme as regras para usuário
        schema = Yup.number();
        break;

      case 'COMBO_BOX_REGISTER_FIELD':
        // Adapte conforme as regras para registro
        schema = Yup.number();
        break;

      case 'COMBO_BOX_FLOW_FIELD':
        // Adapte conforme as regras para fluxo
        schema = Yup.number();
        break;

      case 'CURRENCY_FIELD':
      case 'NUMBER_FIELD':
        schema = Yup.string().matches(/^(?:\d+)?(?:[.,]\d{0,2})?$/, 'Por favor, insira um número válido.');
        break;

      case 'PHONE_FIELD':

        if ((field.required === undefined || field.required === null || Boolean(field.required) === false) && (value === undefined || value === null || value === '')) {

        } else {
          const phoneRegExp = /^(\(\d{2}\)\s?\d{4,5}-?\d{4}|\d{2}\s?\d{4,5}-?\d{4}|\d{11})$/;
          schema = Yup.string().matches(phoneRegExp, 'Formato de telefone inválido');
        }

        break;
      case 'DOC_FIELD':

        const docRegExp = /^(\d{14}|\d{11}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{3}\.\d{3}\.\d{3}-\d{2})$/;

        schema = Yup.string().matches(docRegExp, 'Formato de CPF e CNPJ inválido');

        break;

      default:
        // Adicione um case padrão para evitar um erro de compilação se o tipo não for reconhecido
        schema = Yup.mixed();
        break;
    }

    await schema.validate(newValue);
  } catch (error) {
    errors.push({
      type: 'typeMismatch',
      message: field.title + ": " + error.message || field.title + ": Erro de validação",
      row: row
    });
  }

  return errors;

};

export const getMaskField = (type: string, value: string | number, variation?: string) => {

  let ret: string = String(value);

  if (typeof value === "number") {

    //CURRENCY_FIELD - Money
    if (type === "CURRENCY_FIELD") {

      let currency: string;

      if (!String(value).includes(".")) {
        currency = value + ".00";
      } else {
        currency = String(value)
      }

      ret = Number(currency.replace(/[^0-9.-]+/g, "")).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    } else if (type === "FORMULA_FIELD") {

      let maskedValue = "";

      if (variation === "1") { //Number
        maskedValue = maskFormulaNumber(String(value));
      } else if (variation === "2") { //Currency
        maskedValue = maskFormulaCurrency(String(value));
      } else if (variation === "3") { //Percent
        maskedValue = maskFormulaPercent(String(value));
      } else {
        maskedValue = maskFormulaNumber(String(value));
      }

      ret = maskedValue;

    } else if (type === "NUMBER_FIELD") {

      ret = maskFormulaNumber(String(value));

    }

  }

  return ret;
};

export const getComponentValidationType = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: "string",
    [Fields.TEXT_LONG_FIELD]: "string",
    [Fields.MAIL_FIELD]: "string",
    [Fields.COMBO_BOX_FIELD]: "string",
    [Fields.RADIO_BOX_FIELD]: "string",
    [Fields.CHECK_BOX_FIELD]: "array",
    [Fields.DATE_PICKER_FIELD]: "string",
    [Fields.SWITCH_FIELD]: "boolean",
    [Fields.INPUT_LIST_FIELD]: "array",
    [Fields.COLOR_PICKER_FIELD]: "string",
    [Fields.ICON_PICKER_FIELD]: "string",
    [Fields.CHECK_BOX_ONE_FIELD]: "string",
    [Fields.COMBO_BOX_USER_FIELD]: "string",
    [Fields.TITLE_FIELD]: "string",
    [Fields.DESCRIPTION_FIELD]: "string",
    [Fields.DIVIDER_FIELD]: "string",
    [Fields.DUE_DATE_FIELD]: "string",
    [Fields.CURRENCY_FIELD]: "number",
    [Fields.NUMBER_FIELD]: "number",
    [Fields.PASSWORD_FIELD]: "string",
    [Fields.PHONE_FIELD]: "string",
    [Fields.DOC_FIELD]: "string",
    [Fields.COMBO_BOX_REGISTER_FIELD]: "string",
    [Fields.COMBO_BOX_FLOW_FIELD]: "string",
    [Fields.INPUT_ATTACH_FIELD]: "mixed",
    [Fields.FORMULA_FIELD]: "string",
    [Fields.TEXT_FORMULA_FIELD]: "string",
    [Fields.SLA_CONFIGURATOR_FIELD]: "object",
    [Fields.INPUT_RICH_TEXT_FIELD]: "string",
    [Fields.CUSTOM_SELECT_FIELD]: "string",
    [Fields.CHECK_LIST_FIELD]: "array",
    [Fields.DYNAMIC_TEXT_FIELD]: "string",
    [Fields.ID_FIELD]: "string",
    [Fields.LINK_FIELD]: "string",
  };

  return relationFields[type];
};

export const getComponentValidation = (type: string, name: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.PASSWORD_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.TEXT_LONG_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.MAIL_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      },
      {
        type: "email",
        params: ["O e-mail digitado não é válido!"]
      }
    ],
    [Fields.COMBO_BOX_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.RADIO_BOX_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.CHECK_BOX_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada ao menos uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.DATE_PICKER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.SWITCH_FIELD]: [],
    [Fields.INPUT_LIST_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido ao menos um item no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.COLOR_PICKER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.ICON_PICKER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.CHECK_BOX_ONE_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada ao menos uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.COMBO_BOX_USER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionado ao menos um usuário " + name + " (obrigatório)!"]
      }
    ],
    [Fields.COMBO_BOX_REGISTER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionado ao menos um registro " + name + " (obrigatório)!"]
      }
    ],
    [Fields.COMBO_BOX_FLOW_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionado ao menos um registro " + name + " (obrigatório)!"]
      }
    ],
    [Fields.COMBO_BOX_USER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionado ao menos um usuário " + name + " (obrigatório)!"]
      }
    ],
    [Fields.DUE_DATE_FIELD]: [
      {
        type: "required",
        params: ["Deve ser selecionada uma opção do campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.CURRENCY_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido o valor no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.NUMBER_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido o valor no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.PHONE_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido o valor no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.DOC_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido o valor no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.INPUT_ATTACH_FIELD]: [
      {
        type: "required",
        params: ["Deve ser anexado um arquivo no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.TEXT_FORMULA_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.INPUT_RICH_TEXT_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.CUSTOM_SELECT_FIELD]: [
      {
        type: "required",
        params: ["O campo " + name + " deve ser preenchido (obrigatório)!"]
      }
    ],
    [Fields.CHECK_BOX_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido ao menos um item no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.CHECK_LIST_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido ao menos um item no campo " + name + " (obrigatório)!"]
      }
    ],
    [Fields.LINK_FIELD]: [
      {
        type: "required",
        params: ["Deve ser inserido ao menos um link no campo " + name + " (obrigatório)!"]
      }
    ],
  };

  return relationFields[type];
};

export const getItemComponentDescription = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: "Texto curto",
    [Fields.TEXT_LONG_FIELD]: "Texto longo",
    [Fields.MAIL_FIELD]: "E-mail",
    [Fields.COMBO_BOX_FIELD]: "Caixa de Seleção",
    [Fields.RADIO_BOX_FIELD]: "Seleção única",
    [Fields.CHECK_BOX_FIELD]: "Seleção múltipla",
    [Fields.DATE_PICKER_FIELD]: "Data",
    [Fields.SWITCH_FIELD]: "Interruptor",
    [Fields.INPUT_LIST_FIELD]: "Lista de Itens",
    [Fields.COLOR_PICKER_FIELD]: "Cor",
    [Fields.ICON_PICKER_FIELD]: "Ícone",
    [Fields.CHECK_BOX_ONE_FIELD]: "Seleção única box",
    [Fields.COMBO_BOX_USER_FIELD]: "Responsável",
    [Fields.TITLE_FIELD]: "Título",
    [Fields.DESCRIPTION_FIELD]: "Descrição",
    [Fields.DIVIDER_FIELD]: "Divisor",
    [Fields.DUE_DATE_FIELD]: "Vencimento",
    [Fields.CURRENCY_FIELD]: "Moeda",
    [Fields.NUMBER_FIELD]: "Numérico",
    [Fields.PASSWORD_FIELD]: "Senha",
    [Fields.PHONE_FIELD]: "Telefone",
    [Fields.DOC_FIELD]: "Documentos",
    [Fields.COMBO_BOX_REGISTER_FIELD]: "Meus Cadastros",
    [Fields.COMBO_BOX_FLOW_FIELD]: "Meus Fluxos",
    [Fields.INPUT_ATTACH_FIELD]: "Anexo",
    [Fields.FORMULA_FIELD]: "Fórmula",
    [Fields.TEXT_FORMULA_FIELD]: "Editor de Fórmulas",
    [Fields.SLA_CONFIGURATOR_FIELD]: "Configurador de SLA",
    [Fields.INPUT_RICH_TEXT_FIELD]: "Texto Formatado",
    [Fields.CUSTOM_SELECT_FIELD]: "Campo Customizado",
    [Fields.CHECK_LIST_FIELD]: "Check List",
    [Fields.DYNAMIC_TEXT_FIELD]: "Conteúdo Dinâmico",
    [Fields.ID_FIELD]: "ID",
    [Fields.LINK_FIELD]: "Link",
  };

  return relationFields[type];
};

//#d13e5b - #f23b5c
//#893AE8 - #9e37ed
//#E88B3A - #ff8c2f

export const FieldsTypesColor = {
  TEXT_SHORT_FIELD: "#f23b5c",
  TEXT_LONG_FIELD: "#f23b5c",
  MAIL_FIELD: "#f23b5c",
  COMBO_BOX_FIELD: "#9e37ed",
  RADIO_BOX_FIELD: "#9e37ed",
  CHECK_BOX_FIELD: "#9e37ed",
  DATE_PICKER_FIELD: "#4680B8",
  SWITCH_FIELD: "#ff8c2f",
  INPUT_LIST_FIELD: "#61bd4f",
  COLOR_PICKER_FIELD: "#4680B8",
  ICON_PICKER_FIELD: "#4680B8",
  CHECK_BOX_ONE_FIELD: "#ff8c2f",
  COMBO_BOX_USER_FIELD: "#9e37ed",
  TITLE_FIELD: "#00bcd4",
  DESCRIPTION_FIELD: "#00bcd4",
  DIVIDER_FIELD: "#00bcd4",
  DUE_DATE_FIELD: "#4680B8",
  CURRENCY_FIELD: "#f23b5c",
  NUMBER_FIELD: "#f23b5c",
  PASSWORD_FIELD: "#f23b5c",
  PHONE_FIELD: "#f23b5c",
  DOC_FIELD: "#f23b5c",
  COMBO_BOX_REGISTER_FIELD: "#9e37ed",
  COMBO_BOX_FLOW_FIELD: "#9e37ed",
  INPUT_ATTACH_FIELD: "#9e37ed",
  FORMULA_FIELD: "#4caf50",
  TEXT_FORMULA_FIELD: "#4caf50",
  SLA_CONFIGURATOR_FIELD: "#4caf50",
  INPUT_RICH_TEXT_FIELD: "#f23b5c",
  CUSTOM_SELECT_FIELD: "#f23b5c",
  CHECK_LIST_FIELD: "#61bd4f",
  DYNAMIC_TEXT_FIELD: "#00bcd4",
  ID_FIELD: "#00bcd4",
  LINK_FIELD: "#f23b5c",
};

export const FieldsTypesColorBackground = {
  TEXT_SHORT_FIELD: "#f23b5c1a",
  TEXT_LONG_FIELD: "#f23b5c1a",
  MAIL_FIELD: "#f23b5c1a",
  COMBO_BOX_FIELD: "#9e37ed1a",
  RADIO_BOX_FIELD: "#9e37ed1a",
  CHECK_BOX_FIELD: "#9e37ed1a",
  DATE_PICKER_FIELD: "#4680B81a",
  SWITCH_FIELD: "#ff8c2f1a",
  INPUT_LIST_FIELD: "#61bd4f1a",
  COLOR_PICKER_FIELD: "#4680B81a",
  ICON_PICKER_FIELD: "#4680B81a",
  CHECK_BOX_ONE_FIELD: "#ff8c2f1a",
  COMBO_BOX_USER_FIELD: "#9e37ed1a",
  TITLE_FIELD: "#00bcd41a",
  DESCRIPTION_FIELD: "#00bcd41a",
  DIVIDER_FIELD: "#00bcd41a",
  DUE_DATE_FIELD: "#4680B81a",
  CURRENCY_FIELD: "#f23b5c1a",
  NUMBER_FIELD: "#f23b5c1a",
  PASSWORD_FIELD: "#f23b5c1a",
  PHONE_FIELD: "#f23b5c1a",
  DOC_FIELD: "#f23b5c1a",
  COMBO_BOX_REGISTER_FIELD: "#9e37ed1a",
  COMBO_BOX_FLOW_FIELD: "#9e37ed1a",
  INPUT_ATTACH_FIELD: "#9e37ed1a",
  FORMULA_FIELD: "#4caf501a",
  TEXT_FORMULA_FIELD: "#4caf501a",
  SLA_CONFIGURATOR_FIELD: "#4caf501a",
  INPUT_RICH_TEXT_FIELD: "#f23b5c1a",
  CUSTOM_SELECT_FIELD: "#f23b5c1a",
  CHECK_LIST_FIELD: "#61bd4f1a",
  DYNAMIC_TEXT_FIELD: "#00bcd41a",
  ID_FIELD: "#00bcd41a",
  LINK_FIELD: "#f23b5c1a",
};

export const getItemComponentIcon = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: FiAlignJustify,
    [Fields.TEXT_LONG_FIELD]: BiText,
    [Fields.MAIL_FIELD]: FiMail,
    [Fields.COMBO_BOX_FIELD]: BiChevronDownSquare,
    [Fields.RADIO_BOX_FIELD]: IoMdRadioButtonOn,
    [Fields.CHECK_BOX_FIELD]: FaCheckSquare,
    [Fields.DATE_PICKER_FIELD]: BsCalendarEvent,
    [Fields.SWITCH_FIELD]: IoMdSwitch,
    [Fields.INPUT_LIST_FIELD]: BsList,
    [Fields.COLOR_PICKER_FIELD]: GiPaintBucket,
    [Fields.ICON_PICKER_FIELD]: FaRegImage,
    [Fields.CHECK_BOX_ONE_FIELD]: FaCheckSquare,
    [Fields.COMBO_BOX_USER_FIELD]: IoMdPersonAdd,
    [Fields.TITLE_FIELD]: MdTitle,
    [Fields.DESCRIPTION_FIELD]: MdShortText,
    [Fields.DIVIDER_FIELD]: AiOutlineDash,
    [Fields.DUE_DATE_FIELD]: BsCalendarCheck,
    [Fields.CURRENCY_FIELD]: FaMoneyBill,
    [Fields.NUMBER_FIELD]: TiSortNumerically,
    [Fields.PASSWORD_FIELD]: FaLock,
    [Fields.PHONE_FIELD]: FaPhoneAlt,
    [Fields.DOC_FIELD]: FaIdCard,
    [Fields.COMBO_BOX_REGISTER_FIELD]: FaTable,
    [Fields.COMBO_BOX_FLOW_FIELD]: TiFlowMerge,
    [Fields.INPUT_ATTACH_FIELD]: MdAttachFile,
    [Fields.FORMULA_FIELD]: TbMathFunction,
    [Fields.TEXT_FORMULA_FIELD]: TbMathFunction,
    [Fields.SLA_CONFIGURATOR_FIELD]: TbMathFunction,
    [Fields.INPUT_RICH_TEXT_FIELD]: BsFileEarmarkRichtextFill,
    [Fields.CUSTOM_SELECT_FIELD]: MdDashboardCustomize,
    [Fields.CHECK_LIST_FIELD]: MdChecklist,
    [Fields.DYNAMIC_TEXT_FIELD]: MdTextFields,
    [Fields.ID_FIELD]: FaKey,
    [Fields.LINK_FIELD]: FaLink,
  };

  return relationFields[type];
};

export const getItemComponent = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: TextShortField,
    [Fields.TEXT_LONG_FIELD]: TextAreaInputField,
    [Fields.MAIL_FIELD]: MailField,
    [Fields.COMBO_BOX_FIELD]: ComboBoxField,
    [Fields.RADIO_BOX_FIELD]: RadioBoxField,
    [Fields.CHECK_BOX_FIELD]: CheckBoxField,
    [Fields.DATE_PICKER_FIELD]: DatePickerField,
    [Fields.SWITCH_FIELD]: SwitchField,
    [Fields.INPUT_LIST_FIELD]: InputListField,
    [Fields.COLOR_PICKER_FIELD]: ColorPickerField,
    [Fields.ICON_PICKER_FIELD]: IconPickerField,
    [Fields.CHECK_BOX_ONE_FIELD]: CheckBoxOneField,
    [Fields.COMBO_BOX_USER_FIELD]: ComboBoxUserField,
    [Fields.TITLE_FIELD]: TitleField,
    [Fields.DESCRIPTION_FIELD]: DescriptionField,
    [Fields.DIVIDER_FIELD]: DividerField,
    [Fields.DUE_DATE_FIELD]: DueDateField,
    [Fields.CURRENCY_FIELD]: CurrencyField,
    [Fields.NUMBER_FIELD]: NumberField,
    [Fields.PASSWORD_FIELD]: PasswordField,
    [Fields.PHONE_FIELD]: PhoneField,
    [Fields.DOC_FIELD]: DocField,
    [Fields.COMBO_BOX_REGISTER_FIELD]: ComboBoxRegisterField,
    [Fields.COMBO_BOX_FLOW_FIELD]: ComboBoxFlowField,
    [Fields.INPUT_ATTACH_FIELD]: InputAttachField,
    [Fields.FORMULA_FIELD]: FormulaField,
    [Fields.TEXT_FORMULA_FIELD]: TextAreaFormulaField,
    [Fields.SLA_CONFIGURATOR_FIELD]: SlaConfiguratorField,
    [Fields.INPUT_RICH_TEXT_FIELD]: InputRichTextField,
    [Fields.CUSTOM_SELECT_FIELD]: CustomSelectField,
    [Fields.CHECK_LIST_FIELD]: CheckListField,
    [Fields.DYNAMIC_TEXT_FIELD]: DynamicTextField,
    [Fields.ID_FIELD]: IdField,
    [Fields.LINK_FIELD]: LinkField,
  };

  return relationFields[type];
};

/* Cores Bases
#f23b5c
#4680B8
#9e37ed
#ff8c2f
#61bd4f
*/

export const getItemComponentColor = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: "#f23b5c",
    [Fields.TEXT_LONG_FIELD]: "#f23b5c",
    [Fields.MAIL_FIELD]: "#f23b5c",
    [Fields.COMBO_BOX_FIELD]: "#9e37ed",
    [Fields.RADIO_BOX_FIELD]: "#9e37ed",
    [Fields.CHECK_BOX_FIELD]: "#9e37ed",
    [Fields.DATE_PICKER_FIELD]: "#4680B8",
    [Fields.SWITCH_FIELD]: "#ff8c2f",
    [Fields.INPUT_LIST_FIELD]: "#61bd4f",
    [Fields.COLOR_PICKER_FIELD]: "#4680B8",
    [Fields.ICON_PICKER_FIELD]: "#4680B8",
    [Fields.CHECK_BOX_ONE_FIELD]: "#ff8c2f",
    [Fields.COMBO_BOX_USER_FIELD]: "#9e37ed",
    [Fields.TITLE_FIELD]: "#00bcd4",
    [Fields.DESCRIPTION_FIELD]: "#00bcd4",
    [Fields.DIVIDER_FIELD]: "#00bcd4",
    [Fields.DUE_DATE_FIELD]: "#4680B8",
    [Fields.CURRENCY_FIELD]: "#f23b5c",
    [Fields.NUMBER_FIELD]: "#f23b5c",
    [Fields.PASSWORD_FIELD]: "#f23b5c",
    [Fields.PHONE_FIELD]: "#f23b5c",
    [Fields.DOC_FIELD]: "#f23b5c",
    [Fields.COMBO_BOX_REGISTER_FIELD]: "#9e37ed",
    [Fields.COMBO_BOX_FLOW_FIELD]: "#9e37ed",
    [Fields.INPUT_ATTACH_FIELD]: "#9e37ed",
    [Fields.FORMULA_FIELD]: "#4caf50",
    [Fields.TEXT_FORMULA_FIELD]: "#4caf50",
    [Fields.SLA_CONFIGURATOR_FIELD]: "#4caf50",
    [Fields.INPUT_RICH_TEXT_FIELD]: "#f23b5c",
    [Fields.CUSTOM_SELECT_FIELD]: "#f23b5c",
    [Fields.CHECK_LIST_FIELD]: "#61bd4f",
    [Fields.DYNAMIC_TEXT_FIELD]: "#00bcd4",
    [Fields.ID_FIELD]: "#00bcd4",
    [Fields.LINK_FIELD]: "#f23b5c",
  };

  return relationFields[type];
};

export const getItemComponentColorBackground = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: "#f23b5c1a",
    [Fields.TEXT_LONG_FIELD]: "#f23b5c1a",
    [Fields.MAIL_FIELD]: "#f23b5c1a",
    [Fields.COMBO_BOX_FIELD]: "#9e37ed1a",
    [Fields.RADIO_BOX_FIELD]: "#9e37ed1a",
    [Fields.CHECK_BOX_FIELD]: "#9e37ed1a",
    [Fields.DATE_PICKER_FIELD]: "#4680B81a",
    [Fields.SWITCH_FIELD]: "#ff8c2f1a",
    [Fields.INPUT_LIST_FIELD]: "#61bd4f1a",
    [Fields.COLOR_PICKER_FIELD]: "#4680B81a",
    [Fields.ICON_PICKER_FIELD]: "#4680B81a",
    [Fields.CHECK_BOX_ONE_FIELD]: "#ff8c2f1a",
    [Fields.COMBO_BOX_USER_FIELD]: "#9e37ed1a",
    [Fields.TITLE_FIELD]: "#00bcd41a",
    [Fields.DESCRIPTION_FIELD]: "#00bcd41a",
    [Fields.DIVIDER_FIELD]: "#00bcd41a",
    [Fields.DUE_DATE_FIELD]: "#4680B81a",
    [Fields.CURRENCY_FIELD]: "#f23b5c1a",
    [Fields.NUMBER_FIELD]: "#f23b5c1a",
    [Fields.PASSWORD_FIELD]: "#f23b5c1a",
    [Fields.PHONE_FIELD]: "#f23b5c1a",
    [Fields.DOC_FIELD]: "#f23b5c1a",
    [Fields.COMBO_BOX_REGISTER_FIELD]: "#9e37ed1a",
    [Fields.COMBO_BOX_FLOW_FIELD]: "#9e37ed1a",
    [Fields.INPUT_ATTACH_FIELD]: "#9e37ed1a",
    [Fields.FORMULA_FIELD]: "#4caf501a",
    [Fields.TEXT_FORMULA_FIELD]: "#4caf501a",
    [Fields.SLA_CONFIGURATOR_FIELD]: "#4caf501a",
    [Fields.INPUT_RICH_TEXT_FIELD]: "#f23b5c1a",
    [Fields.CUSTOM_SELECT_FIELD]: "#f23b5c1a",
    [Fields.CHECK_LIST_FIELD]: "#61bd4f1a",
    [Fields.DYNAMIC_TEXT_FIELD]: "#00bcd41a",
    [Fields.ID_FIELD]: "#00bcd41a",
    [Fields.LINK_FIELD]: "#f23b5c1a",
  };

  return relationFields[type];
};

export const getComponentComparationType = (type: string) => {
  const relationFields = {
    [Fields.TEXT_SHORT_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.TEXT_LONG_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.MAIL_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.COMBO_BOX_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.RADIO_BOX_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.CHECK_BOX_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.DATE_PICKER_FIELD]: [
      "Igual a",
      "Antes de",
      "Depois de",
      "Igual a ou depois",
      "Igual a ou antes",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.SWITCH_FIELD]: [
      "Igual a",
    ],
    [Fields.INPUT_LIST_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.COLOR_PICKER_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.ICON_PICKER_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.CHECK_BOX_ONE_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.COMBO_BOX_USER_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.DUE_DATE_FIELD]: [
      "Igual a",
      "Antes de",
      "Depois de",
      "Igual a ou depois",
      "Igual a ou antes",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.CURRENCY_FIELD]: [
      "Igual a",
      "Menor que",
      "Maior que",
      "Igual a ou maior que",
      "Igual a ou menor que",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.FORMULA_FIELD]: [
      "Igual a",
      "Menor que",
      "Maior que",
      "Igual a ou maior que",
      "Igual a ou menor que",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.NUMBER_FIELD]: [
      "Igual a",
      "Menor que",
      "Maior que",
      "Igual a ou maior que",
      "Igual a ou menor que",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.PHONE_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.COMBO_BOX_REGISTER_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.COMBO_BOX_FLOW_FIELD]: [
      "Igual a",
      "Não é igual a",
      "É uma das",
      "Não é uma das",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.DOC_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.INPUT_RICH_TEXT_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.ID_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ],
    [Fields.LINK_FIELD]: [
      "Contém",
      "Não contém",
      "Igual a",
      "Não é igual a",
      "Está em branco",
      "Não está em branco"
    ]
  };

  return relationFields[type];
};

export const TypeFields = [
  {
    id: Fields.TEXT_SHORT_FIELD,
    name: FieldsTypes.TEXT_SHORT_FIELD,
    index: 1,
    icon: FieldsIcons.TEXT_SHORT_FIELD,
    color: FieldsTypesColor.TEXT_SHORT_FIELD,
    color_background: FieldsTypesColorBackground.TEXT_SHORT_FIELD
  },
  {
    id: Fields.TEXT_LONG_FIELD,
    name: FieldsTypes.TEXT_LONG_FIELD,
    index: 2,
    icon: FieldsIcons.TEXT_LONG_FIELD,
    color: FieldsTypesColor.TEXT_LONG_FIELD,
    color_background: FieldsTypesColorBackground.TEXT_LONG_FIELD
  },
  {
    id: Fields.INPUT_RICH_TEXT_FIELD,
    name: FieldsTypes.INPUT_RICH_TEXT_FIELD,
    index: 3,
    icon: FieldsIcons.INPUT_RICH_TEXT_FIELD,
    color: FieldsTypesColor.INPUT_RICH_TEXT_FIELD,
    color_background: FieldsTypesColorBackground.INPUT_RICH_TEXT_FIELD,
  },
  {
    id: Fields.DATE_PICKER_FIELD,
    name: FieldsTypes.DATE_PICKER_FIELD,
    index: 4,
    icon: FieldsIcons.DATE_PICKER_FIELD,
    color: FieldsTypesColor.DATE_PICKER_FIELD,
    color_background: FieldsTypesColorBackground.DATE_PICKER_FIELD
  },
  {
    id: Fields.DUE_DATE_FIELD,
    name: FieldsTypes.DUE_DATE_FIELD,
    index: 5,
    icon: FieldsIcons.DUE_DATE_FIELD,
    color: FieldsTypesColor.DUE_DATE_FIELD,
    color_background: FieldsTypesColorBackground.DUE_DATE_FIELD,
    help_text: "O campo Vencimento irá atribuir automáticamente uma data de vencimento no cartão. Desta forma você pode criar prazos e avisos para conclusão das tarefas."
  },
  {
    id: Fields.COMBO_BOX_USER_FIELD,
    name: FieldsTypes.COMBO_BOX_USER_FIELD,
    index: 6,
    icon: FieldsIcons.COMBO_BOX_USER_FIELD,
    color: FieldsTypesColor.COMBO_BOX_USER_FIELD,
    color_background: FieldsTypesColorBackground.COMBO_BOX_USER_FIELD,
    help_text: "O campo Responsável irá atribuir automáticamente ao responsável do cartão o usuário selecionado. O usuário também visualizará o cartão nas suas tarefas."
  },
  {
    id: Fields.COMBO_BOX_FIELD,
    name: FieldsTypes.COMBO_BOX_FIELD,
    index: 7,
    icon: FieldsIcons.COMBO_BOX_FIELD,
    color: FieldsTypesColor.COMBO_BOX_FIELD,
    color_background: FieldsTypesColorBackground.COMBO_BOX_FIELD
  },
  {
    id: Fields.RADIO_BOX_FIELD,
    name: FieldsTypes.RADIO_BOX_FIELD,
    index: 8,
    icon: FieldsIcons.RADIO_BOX_FIELD,
    color: FieldsTypesColor.RADIO_BOX_FIELD,
    color_background: FieldsTypesColorBackground.RADIO_BOX_FIELD
  },
  {
    id: Fields.CHECK_BOX_FIELD,
    name: FieldsTypes.CHECK_BOX_FIELD,
    index: 9,
    icon: FieldsIcons.CHECK_BOX_FIELD,
    color: FieldsTypesColor.CHECK_BOX_FIELD,
    color_background: FieldsTypesColorBackground.CHECK_BOX_FIELD
  },
  {
    id: Fields.CURRENCY_FIELD,
    name: FieldsTypes.CURRENCY_FIELD,
    index: 10,
    icon: FieldsIcons.CURRENCY_FIELD,
    color: FieldsTypesColor.CURRENCY_FIELD,
    color_background: FieldsTypesColorBackground.CURRENCY_FIELD
  },
  {
    id: Fields.NUMBER_FIELD,
    name: FieldsTypes.NUMBER_FIELD,
    index: 11,
    icon: FieldsIcons.NUMBER_FIELD,
    color: FieldsTypesColor.NUMBER_FIELD,
    color_background: FieldsTypesColorBackground.NUMBER_FIELD
  },
  {
    id: Fields.CHECK_LIST_FIELD,
    name: FieldsTypes.CHECK_LIST_FIELD,
    index: 12,
    icon: FieldsIcons.CHECK_LIST_FIELD,
    color: FieldsTypesColor.CHECK_LIST_FIELD,
    color_background: FieldsTypesColorBackground.CHECK_LIST_FIELD,
    isNew: true
  },
  {
    id: Fields.FORMULA_FIELD,
    name: FieldsTypes.FORMULA_FIELD,
    index: 13,
    icon: FieldsIcons.FORMULA_FIELD,
    color: FieldsTypesColor.FORMULA_FIELD,
    color_background: FieldsTypesColorBackground.FORMULA_FIELD,
    help_text: "Use o campo de Fórmula para cálculos simples como soma, subtração, multiplicação e divisão. Fácil e rápido! Você também pode usar os campos do formulário na fórmula."
  },
  {
    id: Fields.INPUT_ATTACH_FIELD,
    name: FieldsTypes.INPUT_ATTACH_FIELD,
    index: 14,
    icon: FieldsIcons.INPUT_ATTACH_FIELD,
    color: FieldsTypesColor.INPUT_ATTACH_FIELD,
    color_background: FieldsTypesColorBackground.INPUT_ATTACH_FIELD
  },
  {
    id: Fields.COMBO_BOX_REGISTER_FIELD,
    name: FieldsTypes.COMBO_BOX_REGISTER_FIELD,
    index: 15,
    icon: FieldsIcons.COMBO_BOX_REGISTER_FIELD,
    color: FieldsTypesColor.COMBO_BOX_REGISTER_FIELD,
    color_background: FieldsTypesColorBackground.COMBO_BOX_REGISTER_FIELD,
    help_text: "Com o campo Meus Cadastros é possível buscar e criar registros nos Seus Cadastros. Esta é uma ótima forma de manter dados centralizados, como dados mestres do seu negócio."
  },
  {
    id: Fields.COMBO_BOX_FLOW_FIELD,
    name: FieldsTypes.COMBO_BOX_FLOW_FIELD,
    index: 16,
    icon: FieldsIcons.COMBO_BOX_FLOW_FIELD,
    color: FieldsTypesColor.COMBO_BOX_FLOW_FIELD,
    color_background: FieldsTypesColorBackground.COMBO_BOX_FLOW_FIELD,
    help_text: "Com o campo Meus Fluxos é possível buscar e criar cartões de outros Fluxos. Desta forma você conecta os fluxos, podendo um ser dependente do outro."
  },
  {
    id: Fields.MAIL_FIELD,
    name: FieldsTypes.MAIL_FIELD,
    index: 17,
    icon: FieldsIcons.MAIL_FIELD,
    color: FieldsTypesColor.MAIL_FIELD,
    color_background: FieldsTypesColorBackground.MAIL_FIELD
  },
  {
    id: Fields.PHONE_FIELD,
    name: FieldsTypes.PHONE_FIELD,
    index: 18,
    icon: FieldsIcons.PHONE_FIELD,
    color: FieldsTypesColor.PHONE_FIELD,
    color_background: FieldsTypesColorBackground.PHONE_FIELD
  },
  {
    id: Fields.DOC_FIELD,
    name: FieldsTypes.DOC_FIELD,
    index: 19,
    icon: FieldsIcons.DOC_FIELD,
    color: FieldsTypesColor.DOC_FIELD,
    color_background: FieldsTypesColorBackground.DOC_FIELD
  },
  {
    id: Fields.LINK_FIELD,
    name: FieldsTypes.LINK_FIELD,
    index: 20,
    icon: FieldsIcons.LINK_FIELD,
    color: FieldsTypesColor.LINK_FIELD,
    color_background: FieldsTypesColorBackground.LINK_FIELD,
    isNew: true
  },
  {
    id: Fields.SWITCH_FIELD,
    name: FieldsTypes.SWITCH_FIELD,
    index: 21,
    icon: FieldsIcons.SWITCH_FIELD,
    color: FieldsTypesColor.SWITCH_FIELD,
    color_background: FieldsTypesColorBackground.SWITCH_FIELD
  },
  {
    id: Fields.INPUT_LIST_FIELD,
    name: FieldsTypes.INPUT_LIST_FIELD,
    index: 22,
    icon: FieldsIcons.INPUT_LIST_FIELD,
    color: FieldsTypesColor.INPUT_LIST_FIELD,
    color_background: FieldsTypesColorBackground.INPUT_LIST_FIELD
  },
  {
    id: Fields.DYNAMIC_TEXT_FIELD,
    name: FieldsTypes.DYNAMIC_TEXT_FIELD,
    index: 23,
    icon: FieldsIcons.DYNAMIC_TEXT_FIELD,
    color: FieldsTypesColor.DYNAMIC_TEXT_FIELD,
    color_background: FieldsTypesColorBackground.DYNAMIC_TEXT_FIELD,
    isNew: true,
    help_text: "O campo Conteúdo Dinâmico é uma forma de inserir textos dinâmicos no cartão. Você pode misturar textos fixos com campos dinâmicos do formulário, deixando as informações ainda mais personalizadas"
  },
  {
    id: Fields.ID_FIELD,
    name: FieldsTypes.ID_FIELD,
    index: 24,
    icon: FieldsIcons.ID_FIELD,
    color: FieldsTypesColor.ID_FIELD,
    color_background: FieldsTypesColorBackground.ID_FIELD,
    isNew: true,
    help_text: "O campo ID irá exibir o número de identificação do cartão. Este número é único e não pode ser alterado. Ele é muito utilizado para controle de tickets ou solicitações."
  },
  {
    id: Fields.TITLE_FIELD,
    name: FieldsTypes.TITLE_FIELD,
    index: 25,
    icon: FieldsIcons.TITLE_FIELD,
    color: FieldsTypesColor.TITLE_FIELD,
    color_background: FieldsTypesColorBackground.TITLE_FIELD
  },
  {
    id: Fields.DESCRIPTION_FIELD,
    name: FieldsTypes.DESCRIPTION_FIELD,
    index: 26,
    icon: FieldsIcons.DESCRIPTION_FIELD,
    color: FieldsTypesColor.DESCRIPTION_FIELD,
    color_background: FieldsTypesColorBackground.DESCRIPTION_FIELD
  },
  {
    id: Fields.DIVIDER_FIELD,
    name: FieldsTypes.DIVIDER_FIELD,
    index: 27,
    icon: FieldsIcons.DIVIDER_FIELD,
    color: FieldsTypesColor.DIVIDER_FIELD,
    color_background: FieldsTypesColorBackground.DIVIDER_FIELD
  }
];

export const getTypeField = (type: string): TypeFieldProps => {

  const filterdType = TypeFields.filter((tf) => tf.id === type);

  if (filterdType.length > 0) {

    return filterdType[0];
  }

  return TypeFields[0];

}