import {
    AddNewFilter,
    BoxContainer,
    ButtonLink,
    DeleteButton,
    FilterBottom,
    FilterContainer,
    GoFilter,
    CleanFilter,
    BoxContainerOrder,
    Input,
    SearchInput,
    SelectComparator,
    SelectComparatorOrder,
    SelectInput,
    BoxContainerField,
    FieldsContainer,
    FieldViewItem,
    FieldViewItemToggle,
    FieldViewItemTitle,
    FieldViewItemOrigin,
    FieldViewItemDrag,
    FieldViewItemIcon
} from "./style";
import { AiFillThunderbolt, AiOutlineEyeInvisible, AiOutlineSearch } from "react-icons/ai";
import { Box, ClickAwayListener } from "@material-ui/core";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FieldOptionsProps, FieldProps, Fields, getComponentComparationType, getFieldObject } from "../../Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import FilterDate from "../FilterComponents/FilterDate";
import FilterInput from "../FilterComponents/FilterInput";
import FilterSelect from "../FilterComponents/FilterSelect";
import { Flow } from "../../../interfaces/Flow";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineDragIndicator, MdOutlineFilterList } from "react-icons/md";
import { BsSortDown, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { Step } from "../../../interfaces/Step";
import getAccessControl from "../../../middlewares/AccessControl";

export interface FieldViewProps {
    id_field: number;
    form_id: number;
    title: string;
    index: number;
    active: boolean;
    type: string;
    origin: string;
    variation?: string;
    maxLength?: string;
    indexOrigin: number;
    reordered: boolean;
    disabled?: boolean;
}

export interface FilterConditionItem {
    index: number;
    fields: FieldProps[];
    comparators: string[];
    selectedField: FieldProps;
    selectedComparator: string;
    value: string;
    valueOptions?: string[];
    options?: FieldOptionsProps[];
    nameComponent?: string;
}

export interface OrderConditionItem {
    index: number;
    fields?: OrderField[];
    orders?: string[];
    selectedField: OrderField;
    selectedOrder: string;
}

interface OrderField {
    id_field: number;
    form_id?: number;
    type: string;
    title: string;
}

export interface FilterFlowProps {
    fieldView?: FieldViewProps[],
    conditions: FilterConditionItem[],
    orderBy?: OrderConditionItem[],
    searchText?: string,
    flow_view_id?: number
}

interface FilterDialogProps {
    flow?: Flow;
    selectedValue?: FilterFlowProps,
    itemNavBar: number;
    typeUser: string;
    onFilterFlow: (filterFlow: FilterFlowProps, openedDialog: boolean) => void;
}

export interface FilterComponentProps {
    comparator: string;
    value?: string;
    valueOptions?: string[];
    field?: FieldProps;
    options?: FieldOptionsProps[];
    onEvent: (e?: React.FormEvent<HTMLInputElement>, conditions?: string[]) => void;
}

const FilterDialog: React.FC<FilterDialogProps> = ({ flow, selectedValue, itemNavBar, onFilterFlow, typeUser }) => {

    const [fieldsView, setFieldsView] = useState<FieldViewProps[]>(selectedValue?.fieldView !== undefined ? selectedValue.fieldView : []);
    const [conditionItems, setConditionItems] = useState<FilterConditionItem[]>(selectedValue?.conditions !== undefined ? selectedValue.conditions : []);
    const [orderItems, setOrderItems] = useState<OrderConditionItem[]>(selectedValue?.orderBy !== undefined ? selectedValue.orderBy : []);
    const [searchText, setSearchText] = useState<string>(selectedValue?.searchText !== undefined ? selectedValue.searchText : "");
    const [selectedView, setSelectedView] = useState<number | undefined>(selectedValue?.flow_view_id !== undefined ? selectedValue.flow_view_id : undefined);

    const [openFilter, setOpenFilter] = useState(false);
    const [fields, setFields] = useState<FieldProps[]>([]);
    const [filterState, setFilterState] = useState<boolean>();
    const [filterStateMessage, setFilterStateMessage] = useState<string>();

    const [openOrder, setOpenOrder] = useState(false);
    const [fieldsOrder, setFieldsOrder] = useState<OrderField[]>([]);
    const [orderState, setOrderState] = useState<boolean>();
    const [orderStateMessage, setOrderStateMessage] = useState<string>();

    const [openFields, setOpenFields] = useState(false);

    const [searchField, setSearchField] = useState<boolean>();
    const searchInputRef = useRef<HTMLInputElement>(null);

    /* Relationship */
    const ComponentField = {
        TEXT_FILTER: "TEXT_FILTER",
        DATE_FILTER: "DATE_FILTER",
        OPTION_FILTER: "OPTION_FILTER",
    };

    const getFilterComponent = (type: string) => {
        const relationFields = {
            [ComponentField.TEXT_FILTER]: FilterInput,
            [ComponentField.DATE_FILTER]: FilterDate,
            [ComponentField.OPTION_FILTER]: FilterSelect,
        };

        return relationFields[type];
    };

    const getFilterNameComponent = (type: string) => {
        const relationFields = {
            [Fields.TEXT_SHORT_FIELD]: "TEXT_FILTER",
            [Fields.TEXT_LONG_FIELD]: "TEXT_FILTER",
            [Fields.MAIL_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.RADIO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_FIELD]: "OPTION_FILTER",
            [Fields.DATE_PICKER_FIELD]: "DATE_FILTER",
            [Fields.SWITCH_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_LIST_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_ONE_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_USER_FIELD]: "OPTION_FILTER",
            [Fields.DUE_DATE_FIELD]: "DATE_FILTER",
            [Fields.CURRENCY_FIELD]: "TEXT_FILTER",
            [Fields.FORMULA_FIELD]: "TEXT_FILTER",
            [Fields.NUMBER_FIELD]: "TEXT_FILTER",
            [Fields.PHONE_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_REGISTER_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_FLOW_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_RICH_TEXT_FIELD]: "TEXT_FILTER",
            [Fields.ID_FIELD]: "TEXT_FILTER",
            [Fields.LINK_FIELD]: "TEXT_FILTER",
            [Fields.DOC_FIELD]: "TEXT_FILTER",
        };

        return relationFields[type];
    }

    const handleClickAway = () => {
        setOpenFilter(false);
        setOpenOrder(false);
        setOpenFields(false);

        if (searchText === undefined || searchText === "") {
            setSearchField(false);
        }
    };

    const onSearch = (text: React.FormEvent<HTMLInputElement>) => {

        setSearchText(text?.currentTarget.value);

    };

    const onSelectFieldFilter = (field_id: string, index: number) => {

        const fieldSelected = fields.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.comparators = getComponentComparationType(fieldSelected[0].type);
                    condition.selectedComparator = getComponentComparationType(fieldSelected[0].type)[0];
                    condition.selectedField = fieldSelected[0];
                    condition.nameComponent = getFilterNameComponent(fieldSelected[0].type)
                    condition.valueOptions = [];
                    condition.value = "";
                }

                return condition;
            }))

        }

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

    };

    const onSelectFieldOrder = (field_id: string, index: number) => {

        const fieldSelected = fieldsOrder.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            const orderSorter: string[] | undefined = getFieldObject(fieldSelected[0].type).orderSorter;

            if (orderSorter !== undefined) {
                setOrderItems(orderItems.map((order, idx) => {
                    if (idx === index) {
                        order.orders = orderSorter;
                        order.selectedOrder = orderSorter[0];
                        order.selectedField = fieldSelected[0];
                    }

                    return order;
                }))
            }

        }

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openOrder);

    };

    const onSelectComparator = (comparatorName: string, index: number) => {

        setConditionItems(conditionItems.map((condition, idx) => {
            if (idx === index) {
                condition.selectedComparator = comparatorName;

                //Clean the value when is blank
                if (comparatorName === "Está em branco" || comparatorName === "Não está em branco") {
                    condition.value = "";
                }
            }

            return condition;
        }))

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

    };

    const onSelectOrder = (orderName: string, index: number) => {

        setOrderItems(orderItems.map((order, idx) => {
            if (idx === index) {
                order.selectedOrder = orderName;
            }

            return order;
        }))

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openOrder);

    };

    const handleDeleteAllConditionOrder = useCallback(() => {

        setOrderItems([]);

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openOrder);

        setOpenOrder(!openOrder)

    }, [conditionItems, orderItems, searchText, onFilterFlow, openOrder, selectedView, fieldsView]);

    const handleDeleteAllConditionFilter = useCallback(() => {

        let newConditions = [];

        const newConditionItem: FilterConditionItem = {
            index: 0,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            valueOptions: undefined,
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newConditions.push(newConditionItem);

        setConditionItems(newConditions);

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

        setOpenFilter(!openFilter)

    }, [conditionItems, onFilterFlow, fields, openFilter, orderItems, searchText, selectedView, fieldsView]);

    const handleDeleteConditionFilter = useCallback((index: number) => {

        let newConditions = conditionItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                valueOptions: undefined,
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            newConditions.push(newConditionItem);

        }

        setConditionItems(newConditions);

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

    }, [conditionItems, onFilterFlow, fields, openFilter, orderItems, searchText, selectedView, fieldsView]);

    const handleDeleteConditionOrder = useCallback((index: number) => {

        let newConditions = orderItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

            const newOrderItem: OrderConditionItem = {
                index: 0,
                fields: fieldsOrder,
                orders: orderSorter !== undefined ? orderSorter : [],
                selectedField: fieldsOrder[0],
                selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
            }

            newConditions.push(newOrderItem);

        }

        setOrderItems(newConditions);

        onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openOrder);

    }, [orderItems, onFilterFlow, fieldsOrder, openOrder, conditionItems, searchText, selectedView, fieldsView]);

    const onEventFieldComponent = useCallback((index: number, value?: string, conditions?: string[], version?: number) => {

        if (value !== undefined) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.value = value;
                }

                return condition;
            }))

            onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

        } else if (conditions !== undefined && conditions.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.valueOptions = conditions
                }

                return condition;
            }))

            onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);

        }

    }, [onFilterFlow, conditionItems, searchText, openFilter, orderItems, selectedView, fieldsView]);

    const addNewConditionFilter = useCallback(() => {

        let newArr = conditionItems;

        const newConditionItem: FilterConditionItem = {
            index: newArr.length,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newArr.push(newConditionItem);

        setConditionItems(newArr.map((condition) => {
            return condition;
        }));

    }, [fields, conditionItems]);

    const addNewConditionOrder = useCallback(() => {

        let newArr = orderItems;

        const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

        const newOrderItem: OrderConditionItem = {
            index: newArr.length,
            fields: fieldsOrder,
            orders: orderSorter !== undefined ? orderSorter : [],
            selectedField: fieldsOrder[0],
            selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
        }

        newArr.push(newOrderItem);

        setOrderItems(newArr.map((order) => {
            return order;
        }));

    }, [fieldsOrder, orderItems]);

    const addDefaultItemsOrder = useCallback(() => {

        const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

        if (fieldsOrder !== undefined && fieldsOrder.length > 0 && orderItems.length === 0) {

            const newConditionItem: OrderConditionItem = {
                index: 0,
                fields: fieldsOrder,
                orders: orderSorter !== undefined ? orderSorter : [],
                selectedField: fieldsOrder[0],
                selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
            }

            setOrderItems([newConditionItem]);

        }

    }, [fieldsOrder, orderItems.length]);

    const addDefaultItemsFilter = useCallback(() => {

        if (fields !== undefined && fields.length > 0 && conditionItems.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            setConditionItems([newConditionItem]);

        } else {

            //Run into conditions and update the comparators and selectedComparator
            setConditionItems(conditionItems.map((condition) => {
                condition.comparators = getComponentComparationType(condition.selectedField.type);

                if (condition.selectedComparator === undefined || condition.selectedComparator === "") {
                    condition.selectedComparator = getComponentComparationType(condition.selectedField.type)[0];
                }

                return condition;
            }));

        }

    }, [fields, conditionItems]);

    const handleSelectFieldViewItem = useCallback((id_field: number) => {

        setFieldsView(fieldsView.map((f) => {
            if (f.id_field === id_field) {
                f.active = !f.active;
            }
            return f;
        }));

    }, [fieldsView]);

    const onDragEnd = (result: DropResult) => {
        //If was dragged to outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(fieldsView);
        const [draggedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, draggedItem);

        //Update values of items
        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            index: index,
            reordered: true
        }));

        setFieldsView(updatedItems);
    };

    function sortFieldView(a: FieldViewProps, b: FieldViewProps) {

        if (!a.reordered && !b.reordered) {
            if (a.indexOrigin === b.indexOrigin) {
                return a.index - b.index;
            } else {
                return a.indexOrigin - b.indexOrigin;
            }
        } else {
            return a.index - b.index;
        }

    }

    useEffect(() => {
        if (searchText !== "") {
            const timer = setTimeout(() => {
                onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, openFilter);
            }, 350)

            return () => clearTimeout(timer);
        } else {

            let stateDialog = false;
            if (!openFilter && !openOrder) {
                stateDialog = false;
            } else {
                stateDialog = true;
            }

            onFilterFlow({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText, flow_view_id: selectedView }, stateDialog);
        }
    }, [searchText, conditionItems, onFilterFlow, openFilter, orderItems, openOrder, selectedView, fieldsView]);

    useEffect(() => {

        //Update the sort state
        if (orderItems.length !== undefined && orderItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            orderItems.map((cond) => {

                fieldsOn.push(cond.selectedField.title);
                ret = true;

                return cond;
            })

            setOrderState(ret);
            if (ret) {
                let message = "Ordenando por ";

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += fil;
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e " + fil;
                    }

                    return fil;
                });

                setOrderStateMessage(message);
            }

        } else {
            setOrderState(false);
        }

    }, [orderItems]);

    useEffect(() => {

        //Update the filter state
        if (conditionItems.length !== undefined && conditionItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            conditionItems.map((cond) => {
                if (cond.value !== "" || (cond.valueOptions !== undefined && cond.valueOptions.length >= 1) || cond.selectedComparator === "Está em branco" || cond.selectedComparator === "Não está em branco") {
                    ret = true;
                    fieldsOn.push(cond.selectedField.title);
                }

                return cond;
            })

            setFilterState(ret);
            if (ret) {
                let message = "Filtrando por ";

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += fil;
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e " + fil;
                    }

                    return fil;
                });

                setFilterStateMessage(message);
            }

        } else {
            setFilterState(false);
        }

    }, [conditionItems])

    useEffect(() => {

        let newField: FieldProps[] = [];

        //Get all fields 
        if (flow !== undefined) {

            //Prepare standard fields
            //Data de Vencimento
            newField.push({
                id_field: -1,
                name: "-1",
                type: "DATE_PICKER_FIELD",
                title: "Data de Vencimento",
                description: "Data de Vencimento",
                index: -1
            });

            //Data de Criação
            newField.push({
                id_field: -2,
                name: "-2",
                type: "DATE_PICKER_FIELD",
                title: "Data de Criação",
                description: "Data de Criação",
                index: -2
            });

            //Responsável
            newField.push({
                id_field: -3,
                name: "-3",
                type: "COMBO_BOX_USER_FIELD",
                title: "Responsável",
                description: "Responsável",
                index: -3,
                form_id: flow.form_init?.id_form
            });

            //Etapa
            newField.push({
                id_field: -4,
                name: "-4",
                type: "COMBO_BOX_FIELD",
                title: "Etapa",
                description: "Etapa",
                index: -4,
                options: flow.flow_steps ? flow.flow_steps.map((step, index) => {
                    return {
                        id_field_option: step.id_step,
                        field_id: -4,
                        value: String(step.id_step),
                        label: step.name,
                        order: String(index)
                    } as FieldOptionsProps
                }) : undefined
            });

            //Etiqueta
            newField.push({
                id_field: -5,
                name: "-5",
                type: "COMBO_BOX_FIELD",
                title: "Etiqueta",
                description: "Etiqueta",
                index: -5,
                flow_id: flow.id_flow
            });

            if (flow.form_init !== undefined && flow.form_init !== null) {
                if (flow.form_init.fields !== undefined && flow.form_init.fields !== null && flow.form_init.fields.length > 0) {
                    newField.push(...flow.form_init.fields);
                }
            }

            if (flow.flow_steps !== undefined) {
                for (let index = 0; index < flow.flow_steps.length; index++) {
                    const step = flow.flow_steps[index];

                    if (step.form !== undefined) {
                        if (step.form.fields !== undefined && step.form.fields.length > 0) {
                            newField.push(...step.form.fields);
                        }
                    }

                }
            }

            setFields(newField.filter((f) => f.index < 0 || getFieldObject(f.type).onFilter));

            //Prepare the sort fields
            let newOrderFields: OrderField[] = [];

            //Data de Vencimento
            newOrderFields.push({
                id_field: -1,
                type: "DATE_PICKER_FIELD",
                title: "Data de Vencimento"
            });

            //Data de Criação
            newOrderFields.push({
                id_field: -2,
                type: "DATE_PICKER_FIELD",
                title: "Data de Criação"
            });

            //Responsável
            newOrderFields.push({
                id_field: -3,
                type: "TEXT_SHORT_FIELD",
                title: "Responsável"
            });

            //Etapa
            newOrderFields.push({
                id_field: -4,
                type: "TEXT_SHORT_FIELD",
                title: "Etapa"
            });

            //Add all custom fields
            newField.filter((f) => getFieldObject(f.type).onSorter).map((f) => {
                if (f.id_field !== undefined) {
                    newOrderFields.push({
                        id_field: f.id_field,
                        form_id: f.form_id,
                        type: f.type,
                        title: f.title
                    });
                }
                return f;
            });

            setFieldsOrder(newOrderFields);

            //Prepare the sort fields
            let newViewFields: FieldViewProps[] = [];

            //Título
            newViewFields.push({
                id_field: -6,
                form_id: 0,
                title: "Título do Cartão",
                index: 0,
                active: true,
                type: "TEXT_SHORT_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Etapa
            newViewFields.push({
                id_field: -5,
                form_id: 0,
                title: "Etapa",
                index: 1,
                active: true,
                type: "COMBO_BOX_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Etiquetas
            newViewFields.push({
                id_field: -4,
                form_id: 0,
                title: "Etiquetas",
                index: 2,
                active: true,
                type: "COMBO_BOX_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Responsável
            newViewFields.push({
                id_field: -3,
                form_id: 0,
                title: "Responsável",
                index: 3,
                active: true,
                type: "COMBO_BOX_USER_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Data de Vencimento
            newViewFields.push({
                id_field: -2,
                form_id: 0,
                title: "Data de Vencimento",
                index: 4,
                active: true,
                type: "DATE_PICKER_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Data de Criação
            newViewFields.push({
                id_field: -1,
                form_id: 0,
                title: "Data de Criação",
                index: 5,
                active: false,
                type: "DATE_PICKER_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            const indexStart = newViewFields.length;

            newViewFields.push(...newField
                .filter((f) => f.id_field !== undefined && f.id_field > 0 && getFieldObject(f.type).onViewFlow)
                .map((f, index) => {

                    let step: Step | undefined = undefined;

                    let newOrigin = "";
                    if (f.id_field !== undefined && f.id_field < 0) {
                        newOrigin = "Padrão";
                    } else if (f.form_id === flow.form_init_id) {
                        newOrigin = "Formulário Inicial";
                    } else {

                        //find the step with the same form_id of the field
                        step = flow.flow_steps.find((step) => step.form_id === f.form_id);

                        if (step !== undefined) {
                            newOrigin = step.name;
                        } else {
                            newOrigin = "Etapa";
                        }

                    }

                    return {
                        id_field: f.id_field,
                        form_id: f.form_id,
                        title: f.title,
                        index: index + indexStart,
                        type: f.type,
                        variation: f.variation,
                        maxLength: f.max_length,
                        origin: newOrigin,
                        indexOrigin: step !== undefined ? step.index : 0,
                        reordered: false
                    } as FieldViewProps
                }));

            //Restore the selectedValue saved
            if (selectedValue !== undefined && selectedValue.fieldView !== undefined && selectedValue.fieldView !== null && selectedValue.fieldView.length > 0) {
                newViewFields = newViewFields.map((nvf) => {

                    const findViewField = selectedValue.fieldView?.find((f) => f.id_field === nvf.id_field);
                    if (findViewField !== undefined) {
                        nvf.active = findViewField.active;
                        nvf.index = findViewField.index;
                        nvf.reordered = findViewField.reordered;
                    }

                    return nvf;
                })
            } else { //Restore the value from the legacy
                newViewFields = newViewFields.map((nvf) => {

                    const findField = newField.find((f) => f.id_field !== undefined && f.id_field > 0 && nvf.id_field === f.id_field);
                    if (findField !== undefined && findField.card_highlight !== undefined && findField.card_highlight !== null && findField.card_highlight === "1") {
                        nvf.active = true;
                    }

                    return nvf;
                })
            }

            //Order by index origin and after by index
            newViewFields = newViewFields.sort(sortFieldView);

            setFieldsView(newViewFields);

        }

    }, [flow, selectedValue])

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [searchField]);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSearchText('');
                setSearchField(false);
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {

        //Set the new view
        if (selectedValue) {

            if (selectedValue?.conditions) {
                setConditionItems(selectedValue.conditions);
            }

            if (selectedValue?.orderBy) {
                setOrderItems(selectedValue.orderBy);
            }

            if (selectedValue?.searchText) {
                setSearchText(selectedValue.searchText);
            }

            if (selectedValue?.flow_view_id) {
                setSelectedView(selectedValue.flow_view_id);
            } else {
                setSelectedView(undefined);
            }

        }

    }, [selectedValue]);

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box style={{ display: "flex", width: "100%" }}>

                    {itemNavBar !== 2 && itemNavBar !== 4 && getAccessControl(75, typeUser) ?
                        <ButtonLink type="button"
                            onClick={() => {

                                setOpenFields(!openFields);
                                setOpenOrder(false);
                                setOpenFilter(false);

                                if (searchText === undefined || searchText === "") {
                                    setSearchField(false);
                                }
                            }}
                        >
                            <AiOutlineEyeInvisible />
                            <div>Campos</div>
                        </ButtonLink> :
                        <></>
                    }

                    <ButtonLink type="button"
                        onClick={() => {

                            addDefaultItemsFilter();

                            setOpenFilter(!openFilter);
                            setOpenOrder(false);
                            setOpenFields(false);

                            if (searchText === undefined || searchText === "") {
                                setSearchField(false);
                            }
                        }}
                        active={filterState}>
                        <MdOutlineFilterList />
                        <div>{filterState ? filterStateMessage : "Filtrar"}</div>
                    </ButtonLink>

                    {itemNavBar !== 2 ?
                        <ButtonLink type="button"
                            onClick={() => {

                                addDefaultItemsOrder();

                                setOpenOrder(!openOrder);
                                setOpenFilter(false);
                                setOpenFields(false);

                                if (searchText === undefined || searchText === "") {
                                    setSearchField(false);
                                }
                            }}
                            active={orderState}>
                            <BsSortDown />
                            <div>{orderState ? orderStateMessage : "Ordenar"}</div>
                        </ButtonLink> :
                        <></>
                    }

                    {itemNavBar !== 2 && (
                        !searchField ?
                            <ButtonLink type="button"
                                onClick={() => {
                                    setSearchField(true);
                                    setOpenFilter(false);
                                    setOpenFields(false);
                                    setOpenOrder(false);
                                }}>
                                <AiOutlineSearch />
                                <div>Pesquisar</div>
                            </ButtonLink> :
                            <SearchInput>
                                <AiOutlineSearch />
                                <Input
                                    ref={searchInputRef}
                                    onChange={(e) => onSearch(e)}
                                    placeholder="Busque aqui..."
                                    value={searchText}
                                />
                                {searchText !== undefined && searchText !== "" ?
                                    <IoIosCloseCircle
                                        style={{ marginRight: "9px" }}
                                        onClick={() => {
                                            setSearchText("");
                                            setSearchField(false);
                                        }}
                                    /> :
                                    <></>
                                }
                            </SearchInput>
                    )}

                    {openFields ? (
                        <BoxContainerField>

                            <FieldsContainer>
                                <h2>Campos da visualização</h2>
                                <span>Selecione e ordene o que você deseja exibir na visualização</span>
                            </FieldsContainer>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="selectFieldView">
                                    {(provided) => (
                                        <div  {...provided.droppableProps} ref={provided.innerRef}>
                                            {fieldsView.sort(sortFieldView).map((f, index) => {

                                                const itemsToDisable = [-6, -5, -4, -3, -2];

                                                if (itemNavBar === 0) {
                                                    if (f.id_field !== undefined && itemsToDisable.includes(f.id_field)) {
                                                        f.disabled = true;
                                                    }
                                                } else {
                                                    f.disabled = false;
                                                }

                                                const Icon = getFieldObject(f.type).icon;

                                                return (
                                                    <Draggable
                                                        key={f.id_field}
                                                        draggableId={f.id_field.toString()}
                                                        index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <FieldViewItem onClick={() => !f.disabled ? handleSelectFieldViewItem(f.id_field) : {}}>
                                                                    <FieldViewItemToggle>
                                                                        {!f.disabled ?
                                                                            f.active !== undefined && f.active === true ?
                                                                                <BsToggleOn color="green" /> :
                                                                                <BsToggleOff /> :
                                                                            <BsToggleOn color="gray" />
                                                                        }
                                                                    </FieldViewItemToggle>
                                                                    <FieldViewItemIcon><Icon /></FieldViewItemIcon>
                                                                    <FieldViewItemTitle>{f.title}</FieldViewItemTitle>
                                                                    <FieldViewItemOrigin>{f.origin}</FieldViewItemOrigin>
                                                                    <FieldViewItemDrag className="drag-handle">
                                                                        <MdOutlineDragIndicator />
                                                                    </FieldViewItemDrag>
                                                                </FieldViewItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </BoxContainerField>
                    ) : <></>
                    }

                    {openFilter ? (
                        <BoxContainer itemnavbar={itemNavBar}>

                            {conditionItems.map((condition, index) => {

                                const nameComponent: string = getFilterNameComponent(condition.selectedField.type);

                                let FilterComponent = getFilterComponent(nameComponent);

                                return (

                                    <FilterContainer key={condition.index}>

                                        <SelectInput value={condition.selectedField.id_field} onChange={(e) => onSelectFieldFilter(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Data de Vencimento"}</option>
                                                <option key={-2} value={-2}>{"Data de Criação"}</option>
                                                <option key={-3} value={-3}>{"Responsável"}</option>
                                                <option key={-4} value={-4}>{"Etapa"}</option>
                                                <option key={-5} value={-5}>{"Etiqueta"}</option>
                                            </optgroup>
                                            {fields && fields.filter((f) => f.index >= 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {fields.filter((f) => f.index >= 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>}
                                        </SelectInput>

                                        <SelectComparator value={condition.selectedComparator} onChange={(e) => onSelectComparator(e.currentTarget.value, index)}>
                                            {condition.comparators.map((comparator) => {
                                                return (
                                                    <option key={comparator} value={comparator}>{comparator}</option>
                                                )
                                            })}
                                        </SelectComparator>

                                        <FilterComponent
                                            key={condition.index}
                                            comparator={condition.selectedComparator}
                                            onEvent={(e, cond) => onEventFieldComponent(index, e?.currentTarget.value, cond)}
                                            value={condition.value}
                                            valueOptions={condition.valueOptions}
                                            field={condition.selectedField}
                                            options={condition.selectedField.options}
                                        />

                                        <DeleteButton onClick={() => handleDeleteConditionFilter(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(conditionItems === undefined || conditionItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possuí campos para filtrar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewConditionFilter()}>
                                        <FaPlus />
                                        Adicionar condição
                                    </AddNewFilter>

                                    <CleanFilter onClick={() => handleDeleteAllConditionFilter()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                    <GoFilter onClick={() => setOpenFilter(!openFilter)}>
                                        <AiFillThunderbolt />
                                        Filtrar
                                    </GoFilter>

                                </FilterBottom>

                            )}

                        </BoxContainer>
                    ) : <></>
                    }

                    {openOrder ? (
                        <BoxContainerOrder>

                            {orderItems.map((order, index) => {

                                return (

                                    <FilterContainer key={order.index}>

                                        <SelectInput value={order.selectedField.id_field} onChange={(e) => onSelectFieldOrder(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Data de Vencimento"}</option>
                                                <option key={-2} value={-2}>{"Data de Criação"}</option>
                                                <option key={-3} value={-3}>{"Responsável"}</option>
                                                <option key={-4} value={-4}>{"Etapa"}</option>
                                            </optgroup>

                                            {fieldsOrder && fieldsOrder.filter((f) => f.id_field > 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {fieldsOrder.filter((f) => f.id_field > 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>
                                            }
                                        </SelectInput>

                                        <SelectComparatorOrder value={order.selectedOrder} onChange={(e) => onSelectOrder(e.currentTarget.value, index)}>
                                            {order.orders ?
                                                order.orders.map((order) => {
                                                    return (
                                                        <option key={order} value={order}>{order}</option>
                                                    )
                                                }) :
                                                <></>
                                            }
                                        </SelectComparatorOrder>

                                        <DeleteButton onClick={() => handleDeleteConditionOrder(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(orderItems === undefined || orderItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possuí campos para ordenar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewConditionOrder()}>
                                        <FaPlus />
                                        Adicionar ordenação
                                    </AddNewFilter>

                                    <CleanFilter style={{ marginRight: '20px' }} onClick={() => handleDeleteAllConditionOrder()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                    <GoFilter onClick={() => setOpenOrder(!openOrder)}>
                                        <AiFillThunderbolt />
                                        Ordenar
                                    </GoFilter>

                                </FilterBottom>

                            )}

                        </BoxContainerOrder>
                    ) : <></>
                    }

                </Box>
            </ClickAwayListener >
        </>
    );
};
export default FilterDialog;
