import {
    Container,
    LinkData,
    LinkDataUrl,
    TaskAlert,
    TaskAlertLeft,
    TaskAlertRight,
    TaskBody,
    TaskBodyItem,
    TaskBodyItemBody,
    TaskBodyItemHead,
    TaskBottom,
    TaskBottomLeft,
    TaskBottomRight,
    TaskBottomRightItem,
    TaskContainer,
    TaskHeader,
    TaskHeaderLeft,
    TaskTag,
    TaskTitle
} from "./styles";
import React, { useCallback, useEffect, useState } from "react";

import { BiTime } from "react-icons/bi";
import { Card } from "../../../interfaces/Card";
import { FaCheck, FaExternalLinkAlt } from "react-icons/fa";
import { KanbanConfigProps } from "..";
import { getItemComponentIcon } from "../../Forms/Fields/FieldBuilder";
import { TbCheckbox } from "react-icons/tb";
import { FieldViewProps, FilterFlowProps } from "../../FilterBar/FilterDialog";
import { FormAnswerField } from "../../../interfaces/FormAnswerField";
import { FormAnswer } from "../../../interfaces/FormAnswer";
import AvatarCange from "../../AvatarCange";
import CheckListView from "../../Forms/Fields/Components/CheckListField/CheckListView";
import ComboBoxFlowProgressView from "../../Forms/Fields/Components/ComboBoxFlow/ComboBoxFlowProgressView";
import ComboBoxFlowStepView from "../../Forms/Fields/Components/ComboBoxFlow/ComboBoxFlowStepView";

interface TaskProps {
    card: Card;
    kanban_config?: KanbanConfigProps;
    filterFlow?: FilterFlowProps;
    color: string;
    hideDetails?: boolean;
    openDetail: (card: Card, kanban_config?: KanbanConfigProps) => void;
}

const Task: React.FC<TaskProps> = ({ color, card, kanban_config, openDetail, hideDetails, filterFlow }) => {

    const [checkListLabel, setCheckListLabel] = useState<string>("");
    const [isDoneCheckList, setIsDoneCheckList] = useState<boolean>(false);
    const [highlights, setHighlights] = useState<FormAnswerField[]>([]);

    const getCardHighlights = useCallback((fieldView?: FieldViewProps[]) => {

        let newHighlights: FormAnswerField[] = [];

        if (fieldView !== undefined && fieldView.length > 0) {
            const fieldViewActive = fieldView.filter((fv) => fv.active);

            if (fieldViewActive !== undefined && fieldViewActive.length > 0) {
                for (let index = 0; index < fieldViewActive.length; index++) {
                    const fv = fieldViewActive[index];

                    let value: FormAnswerField[] | undefined = undefined;

                    //Data de Criação
                    if (fv.id_field === -1) {

                        let label = "";
                        if (card.dt_created !== undefined && card.dt_created !== null) {
                            const date = new Date(card.dt_created);
                            label = date.toLocaleDateString('pt-BR');
                        }

                        //create the object to add on value
                        const newFormAnswer: FormAnswerField = {
                            id_form_answer_field: -1,
                            form_answer_id: -1,
                            field_id: -1,
                            index: 0,
                            value: card.dt_created,
                            valueString: label,
                            field: {
                                id_field: -1,
                                index: -1,
                                name: "-1",
                                form_id: -1,
                                type: "DATE_PICKER_FIELD",
                                title: "Data de Criação",
                                description: "Data de Criação"
                            }
                        }

                        newFormAnswer.Icon = getItemComponentIcon(newFormAnswer.field.type);

                        newHighlights.push(newFormAnswer);

                    } else if (card.form_answers !== undefined && card.form_answers.length > 0) {

                        //Filter the form answer with the same form_id
                        const formAnswer = card.form_answers.filter((answer: FormAnswer) => answer.form_id === fv.form_id);

                        //Get the form answer with the biggest id
                        let newerFormAnswer: FormAnswer | undefined = undefined;
                        if (formAnswer !== undefined && formAnswer.length > 1) {
                            newerFormAnswer = formAnswer.reduce((prev: FormAnswer, current: FormAnswer) => (prev.id_form_answer > current.id_form_answer) ? prev : current);
                        } else {
                            newerFormAnswer = formAnswer[0];
                        }

                        //Get the form answer field with the same field_id
                        if (newerFormAnswer !== undefined && newerFormAnswer.form_answer_fields !== undefined && newerFormAnswer.form_answer_fields.length > 0) {
                            value = newerFormAnswer.form_answer_fields.filter((answer: FormAnswerField) => answer.field_id === fv.id_field);
                        }
                    } else {
                        value = undefined;
                    }

                    if (value !== undefined && value.length > 0) {

                        //Add the Icon to the field
                        value.map((answer) => {
                            return answer.Icon = getItemComponentIcon(answer.field.type);
                        });

                        newHighlights.push(...value)

                    }

                }

            }
        }

        setHighlights(newHighlights);

    }, [card.form_answers, card.dt_created]);

    useEffect(() => {

        getCardHighlights(filterFlow?.fieldView);

        if (card.form_answers_highlitgh !== undefined) {
            card.form_answers_highlitgh?.map((answer) => {
                return answer.Icon = getItemComponentIcon(answer.field.type);
            });
        }

        // Checklists label
        if (card.checklists !== undefined) {
            let checked = 0;
            let total = 0;

            card.checklists.map((checklist) => {
                checklist.checklist_items?.map((item) => {
                    if (item.checked === 'S') {
                        checked++;
                    }
                    total++;
                    return item;
                });
                return checklist;
            });

            if (total > 0) {
                setCheckListLabel(checked + "/" + total);
                if (checked === total) {
                    setIsDoneCheckList(true);
                } else {
                    setIsDoneCheckList(false);
                }
            } else {
                setCheckListLabel("");
            }
        } else {
            setCheckListLabel("");
        }

    }, [card, filterFlow, getCardHighlights]);

    return (
        <div id="tour-flow-3" className="card-task" key={card.id_card} onClick={() => openDetail(card, kanban_config)}>
            <Container>
                <TaskContainer color={color + "1a"}>
                    <TaskHeader>
                        <TaskHeaderLeft>
                            {card.card_flow_tags?.map((tag) => {
                                return (
                                    <TaskTag key={tag.id_card_flow_tag} color={tag.flow_tag.color}>{tag.flow_tag.description}</TaskTag>
                                );
                            })}
                        </TaskHeaderLeft>
                    </TaskHeader>
                    <TaskTitle>
                        <h1>{card?.title}</h1>
                    </TaskTitle>
                    <TaskBody>
                        {highlights !== undefined && !hideDetails && highlights.map((item) => {

                            //Valid if is the first CHECK_LIST_FIELD
                            if (item.field.type === "CHECK_LIST_FIELD" && item.index === 1) {

                                //Get all the CHECK_LIST_FIELD from the same item.field_id
                                const checkListFields = highlights.filter((field) => field.field_id === item.field_id);

                                return (
                                    <TaskBodyItem key={item.id_form_answer_field}>
                                        <TaskBodyItemHead>
                                            {item.Icon !== undefined ?
                                                <item.Icon /> :
                                                <></>
                                            }
                                            <h3>{item.field.title}</h3>
                                        </TaskBodyItemHead>
                                        <TaskBodyItemBody>
                                            <CheckListView key={-1} value={checkListFields} colorBar={color} onlyPercent />
                                        </TaskBodyItemBody>
                                    </TaskBodyItem>
                                )

                            } else if (item.field.type === "CHECK_LIST_FIELD") {
                                return <></>;
                            } else if (item.field.type === "COMBO_BOX_FLOW_FIELD" && String(item.field.max_length) === "2" && item.index === 0) {

                                //Get all the CHECK_LIST_FIELD from the same item.field_id
                                const formAnswersField = highlights.filter((field) => field.field_id === item.field_id);

                                return (
                                    <TaskBodyItem key={item.id_form_answer_field}>
                                        <TaskBodyItemHead>
                                            {item.Icon !== undefined ?
                                                <item.Icon /> :
                                                <></>
                                            }
                                            <h3>{item.field.title}</h3>
                                        </TaskBodyItemHead>
                                        <TaskBodyItemBody>
                                            <ComboBoxFlowProgressView key={-1} value={formAnswersField} colorBar={color} />
                                        </TaskBodyItemBody>
                                    </TaskBodyItem>
                                )

                            } else if (item.field.type === "COMBO_BOX_FLOW_FIELD" && item.index === 0) {

                                //Get all the CHECK_LIST_FIELD from the same item.field_id
                                const formAnswersField = highlights.filter((field) => field.field_id === item.field_id);

                                return (
                                    <TaskBodyItem key={item.id_form_answer_field}>
                                        <TaskBodyItemHead>
                                            {item.Icon !== undefined ?
                                                <item.Icon /> :
                                                <></>
                                            }
                                            <h3>{item.field.title}</h3>
                                        </TaskBodyItemHead>
                                        <TaskBodyItemBody style={{ marginLeft: '0px' }}>
                                            <ComboBoxFlowStepView key={-1} value={formAnswersField} />
                                        </TaskBodyItemBody>
                                    </TaskBodyItem>
                                )

                            } else if (item.field.type === "COMBO_BOX_FLOW_FIELD") {
                                return <></>;
                            } else if (item.field.type === "LINK_FIELD") {

                                return (
                                    <TaskBodyItem key={item.id_form_answer_field}>
                                        <TaskBodyItemHead>
                                            {item.Icon !== undefined ?
                                                <item.Icon /> :
                                                <>sd</>
                                            }
                                            <h3>{item.field.title}</h3>
                                        </TaskBodyItemHead>
                                        <TaskBodyItemBody>
                                            <a href={item.value} target="_blank" rel="noreferrer">
                                                <LinkData color={color}>
                                                    <LinkDataUrl>
                                                        {item.value}
                                                    </LinkDataUrl>
                                                    <FaExternalLinkAlt style={{ marginLeft: '5px', fontSize: '10px' }} />
                                                </LinkData>
                                            </a>
                                        </TaskBodyItemBody>
                                    </TaskBodyItem>
                                )
                            }

                            return (
                                <TaskBodyItem key={item.id_form_answer_field}>
                                    <TaskBodyItemHead>
                                        {item.Icon !== undefined ?
                                            <item.Icon /> :
                                            <>sd</>
                                        }
                                        <h3>{item.field.title}</h3>
                                    </TaskBodyItemHead>
                                    <TaskBodyItemBody>{item.valueString}</TaskBodyItemBody>
                                </TaskBodyItem>
                            )
                        })}
                    </TaskBody>
                    <TaskBottom>
                        <TaskBottomLeft>
                            {card.user !== undefined ?
                                <AvatarCange user={card.user} size="30" /> :
                                <></>
                            }
                        </TaskBottomLeft>

                        <TaskBottomRight>
                            {card.checklists !== undefined && card.checklists.length > 0 && checkListLabel !== "" ?
                                <TaskBottomRightItem isDone={isDoneCheckList}>
                                    <TbCheckbox />
                                    {checkListLabel}
                                </TaskBottomRightItem> :
                                <></>
                            }
                        </TaskBottomRight>
                    </TaskBottom>
                </TaskContainer>
                <TaskAlert
                    color={card.dt_due_color !== undefined && card.complete !== "S" ? card.dt_due_color : color}
                    isFilled={card.dt_due_string !== undefined ? true : false}
                >
                    {card.dt_due_string !== undefined ?
                        <>
                            <TaskAlertLeft>
                                {card.complete === "S" ?
                                    <FaCheck /> :
                                    <BiTime />
                                }
                            </TaskAlertLeft>
                            <TaskAlertRight>
                                {card.complete === "S" ?
                                    <h1><s>Finalizada</s></h1> :
                                    <h1>{card.dt_due_card_string}</h1>
                                }
                            </TaskAlertRight>
                        </> :
                        <></>
                    }

                </TaskAlert>

            </Container>
        </div>
    );

}

export default Task;