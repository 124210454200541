import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    AddButton,
    AvatarContainer,
    AvatarHiddenContainer,
    BoxContainer,
    BoxItemsContainer,
    BoxSearchContainer,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRigth,
    Container,
    RemoveButton,
    SearchInput,
    SelectedIcon
} from "./style";
import { User } from "../../interfaces/User";
import AvatarCange from "../AvatarCange";
import { IoIosCloseCircle } from "react-icons/io";
import getAccessControl from "../../middlewares/AccessControl";
import { ClickAwayListener } from "@material-ui/core";
import api from "../../services/api";
import { CardUser } from "../../interfaces/CardUser";
import { FaBell } from "react-icons/fa";

interface AvatarListCangeProps {
    users: CardUser[];
    flow_id: number;
    typeUser: string;
    onAddUser: (user_id: number) => void;
    onRemoveUser: (user_id?: number) => void;
    maxVisibleAvatars?: number;
}

const AvatarListCange: React.FC<AvatarListCangeProps> = ({ users, onAddUser, onRemoveUser, maxVisibleAvatars, typeUser, flow_id }) => {

    const filteredUsers = users.filter(user => user.isFollower === "S");
    const visibleUsers = maxVisibleAvatars ? filteredUsers.slice(0, maxVisibleAvatars) : users;
    const totUserHidden = filteredUsers.length - visibleUsers.length;

    const [open, setOpen] = React.useState(false);
    const [searchValue] = React.useState<string>();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [usersBase, setUsersBase] = useState<User[]>();
    const [usersFiltered, setUsersFiltered] = useState<User[]>([]);

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (usersBase !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let usersNew = usersBase;

            const usersName = usersNew.filter((user) => user.name.toLowerCase().includes(searchText));

            setUsersFiltered(usersName);

        }

    }, [usersBase]);

    const handleSelectUser = useCallback(async (user: User) => {

        if (user !== undefined && user.id_user !== undefined) {
            onAddUser(user.id_user);

            //Set selected for users that are already selected
            let userSelected = user;
            let newUserBase = usersBase;

            if (userSelected !== undefined && newUserBase !== undefined) {
                userSelected.isSelected = true;

                newUserBase = newUserBase.map((u) => {
                    if (u.id_user === userSelected.id_user) {
                        return userSelected;
                    }
                    return u;
                });

                setUsersFiltered(newUserBase);
            }

            handleClickAway();
        }

    }, [onAddUser, usersBase]);

    useEffect(() => {

        if (flow_id !== undefined) {

            api.get(`/user/by-flow`, {
                params: {
                    id_flow: flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;
                    setUsersBase(respStep);
                }
            });

        }

    }, [flow_id]);

    useEffect(() => {

        if (usersBase !== undefined) {

            //Set selected for users that are already selected
            usersBase.forEach((user) => {
                const userSelected = users.find((u) => u.user?.id_user === user.id_user);
                if (userSelected) {
                    user.isSelected = true;
                } else {
                    user.isSelected = false;
                }
            });

            setUsersFiltered(usersBase);

        }

    }, [usersBase, users, open]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [open]);

    return (
        <Container>
            {visibleUsers.map(follower => (
                <AvatarContainer key={follower.id_card_user}>
                    <AvatarCange user={follower.user} size={"30"} />
                    <RemoveButton onClick={() => onRemoveUser(follower.user?.id_user)}>
                        <IoIosCloseCircle />
                    </RemoveButton>
                </AvatarContainer>
            ))}

            {totUserHidden > 0 && (
                <AvatarHiddenContainer>
                    +{totUserHidden}
                </AvatarHiddenContainer>
            )}

            {getAccessControl(56, typeUser) && (
                <AddButton onClick={() => setOpen(true)}>
                    <span className="plus-add-button">+</span>

                    {open && getAccessControl(56, typeUser) ? (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <BoxContainer>

                                <BoxSearchContainer>
                                    <SearchInput
                                        placeholder="Buscar usuários"
                                        defaultValue={searchValue}
                                        onChange={handleFilterUsers}
                                        ref={searchInputRef}
                                    >

                                    </SearchInput>
                                </BoxSearchContainer>

                                <BoxItemsContainer>
                                    {usersFiltered.map((user) => {
                                        return (
                                            <BoxUserItem key={user.email} onClick={() => handleSelectUser(user)}>
                                                <BoxUserLeft>
                                                    <AvatarCange user={user} size="30" />
                                                    {user.isSelected && (
                                                        <SelectedIcon>
                                                            <FaBell />
                                                        </SelectedIcon>
                                                    )}
                                                </BoxUserLeft>
                                                <BoxUserRigth>
                                                    <BoxUserName>{user.name}</BoxUserName>
                                                    <BoxUserMail>{user.email}</BoxUserMail>
                                                </BoxUserRigth>
                                            </BoxUserItem>
                                        )
                                    })}

                                    {usersFiltered.length === 0 && (
                                        <BoxUserItem>
                                            <BoxUserRigth>
                                                <BoxUserName>Convide mais pessoas para o seu fluxo</BoxUserName>
                                            </BoxUserRigth>
                                        </BoxUserItem>
                                    )}
                                </BoxItemsContainer>
                            </BoxContainer>
                        </ClickAwayListener>
                    ) : null
                    }

                </AddButton>
            )}
        </Container >
    );

}

export default AvatarListCange;