import { format, isAfter, isBefore, isSameDay } from 'date-fns';

import { Card } from "../interfaces/Card";
import { FilterConditionItem } from "../components/FilterBar/FilterDialog";
import convertDMYtoDate from '../utils/convertDMYtoDate';
import getDynamicDate from '../utils/getDynamicDate';

export default function getCardFiltered(cards: Card[], user_id: number, conditions?: FilterConditionItem[], filterSearchText?: string): Card[] {

    let newCards = cards;

    //Search Bar
    if (filterSearchText !== undefined && filterSearchText !== "") {

        newCards = newCards.filter((card) => {

            let ret = false;

            card.form_answers?.map((answer) => {

                answer.form_answer_fields.map((answer_field) => {

                    const answerFieldValue = answer_field.valueString !== undefined && answer_field.valueString !== null ? answer_field.valueString.toLocaleLowerCase() : "";

                    if (answerFieldValue.includes(filterSearchText.toLocaleLowerCase())) {
                        ret = true;
                    }

                    return answer_field;
                })

                return answer;
            })

            return ret
        })

    }

    //Filter component
    if (conditions !== undefined) {
        for (let index = 0; index < conditions.length; index++) {
            const condition = conditions[index];

            //Valid if can keep - Check here
            const validExec = (condition.valueOptions === undefined || condition.valueOptions.length < 1) && (condition.value === undefined || condition.value === "") && condition.selectedComparator !== "Está em branco" && condition.selectedComparator !== "Não está em branco" ? false : true;

            if (validExec) {

                newCards = newCards.filter((card) => {

                    let ret = true;
                    let findField = false;

                    card.form_answers?.map((answer) => {

                        if (condition.selectedField.id_field === -1) { //Data de Vencimento

                            findField = true;

                            if (card.dt_due_string !== undefined) {

                                const answerFieldValue = card.dt_due_string.toLocaleLowerCase();
                                const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                if (value !== undefined && value.includes("-") && value !== "-4") {

                                    const dynamicFilter = getDynamicDate(value);

                                    if (dynamicFilter.isPeriod && dynamicFilter.endDate !== undefined) {

                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));
                                        const dtStartDate = dynamicFilter.startDate;
                                        const dtEndDate = dynamicFilter.endDate;

                                        ret = (isBefore(dtItemDate, dtEndDate) || isSameDay(dtItemDate, dtEndDate)) && (isAfter(dtItemDate, dtStartDate) || isSameDay(dtItemDate, dtStartDate));

                                    } else {

                                        const dtTargetDate = dynamicFilter.startDate;
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isSameDay(dtItemDate, dtTargetDate);

                                    }

                                } else if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isSameDay(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isBefore(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isAfter(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                    ret = value !== answerFieldValue;
                                } else if (condition.selectedComparator === "Está em branco") {
                                    ret = answerFieldValue === "" || answerFieldValue === undefined;
                                } else if (condition.selectedComparator === "Não  está em branco") {
                                    ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                }

                            } else if (card.dt_due_string === undefined && condition.selectedComparator === "Está em branco") {
                                ret = true;
                            } else {
                                ret = false;
                            }

                        } else if (condition.selectedField.id_field === -2) { //Data de Criação

                            findField = true;

                            if (card.dt_created !== undefined) {

                                const answerFieldValue = format(new Date(card.dt_created), "dd/MM/yyyy HH:mm");
                                const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                if (value !== undefined && value.includes("-") && value !== "-4") {

                                    const dynamicFilter = getDynamicDate(value);

                                    if (dynamicFilter.isPeriod && dynamicFilter.endDate !== undefined) {

                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));
                                        const dtStartDate = dynamicFilter.startDate;
                                        const dtEndDate = dynamicFilter.endDate;

                                        ret = (isBefore(dtItemDate, dtEndDate) || isSameDay(dtItemDate, dtEndDate)) && (isAfter(dtItemDate, dtStartDate) || isSameDay(dtItemDate, dtStartDate));

                                    } else {

                                        const dtTargetDate = dynamicFilter.startDate;
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isSameDay(dtItemDate, dtTargetDate);

                                    }

                                } else if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isSameDay(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isBefore(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isAfter(dtItemDate, dtTargetDate);

                                } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                    const dtTargetDate = new Date(convertDMYtoDate(value));
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                    ret = value !== answerFieldValue;
                                } else if (condition.selectedComparator === "Está em branco") {
                                    ret = answerFieldValue === "" || answerFieldValue === undefined;
                                } else if (condition.selectedComparator === "Não  está em branco") {
                                    ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                }

                            } else if (card.dt_created === undefined && condition.selectedComparator === "Está em branco") {
                                ret = true;
                            } else {
                                ret = false;
                            }

                        } else if (condition.selectedField.id_field === -3) { //Responsável

                            findField = true;

                            const answerFieldValue = String(card.user_id);
                            let values = condition.valueOptions;

                            //Valid if the values has the -1 value to change to the logged user
                            if (values !== undefined && values.length > 0 && values.includes("-1")) {
                                values = values.map((value) => {
                                    if (value === "-1") {
                                        return String(user_id);
                                    }
                                    return value;
                                })
                            }

                            if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                            } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = false;
                                } else {
                                    ret = true;
                                }
                            } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                            } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = false;
                                } else {
                                    ret = true;
                                }
                            } else if (condition.selectedComparator === "Está em branco") {
                                ret = answerFieldValue === "" || answerFieldValue === undefined || answerFieldValue === 'null' || answerFieldValue === null;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = answerFieldValue !== "" && answerFieldValue !== undefined && answerFieldValue !== 'null' && answerFieldValue !== null;
                            }

                        } else if (condition.selectedField.id_field === -4) { //Etapa 

                            findField = true;

                            const answerFieldValue = String(card.flow_step_id);
                            const values = condition.valueOptions;

                            if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                            } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = false;
                                } else {
                                    ret = true;
                                }
                            } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                            } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                if (filterConditions.length >= 1) {
                                    ret = false;
                                } else {
                                    ret = true;
                                }
                            } else if (condition.selectedComparator === "Está em branco") {
                                ret = answerFieldValue === "" || answerFieldValue === undefined || answerFieldValue === 'null' || answerFieldValue === null;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = answerFieldValue !== "" && answerFieldValue !== undefined && answerFieldValue !== 'null' && answerFieldValue !== null;
                            }

                        } else if (condition.selectedField.id_field === -5) { //Etiqueta 

                            findField = true;

                            if (card.card_flow_tags !== undefined && card.card_flow_tags.length > 0) {

                                const answerFieldValue = card.card_flow_tags;
                                const values = condition.valueOptions;

                                if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                    ret = answerFieldValue?.some((tag) => values.includes(String(tag.flow_tag_id)));
                                } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                    ret = !answerFieldValue?.some((tag) => values.includes(String(tag.flow_tag_id)));
                                } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {
                                    ret = answerFieldValue?.some((tag) => values.includes(String(tag.flow_tag_id)));
                                } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {
                                    ret = !answerFieldValue?.some((tag) => values.includes(String(tag.flow_tag_id)));
                                }
                            } else {
                                ret = false;
                            }

                            if (condition.selectedComparator === "Está em branco") {
                                ret = card.card_flow_tags === undefined || card.card_flow_tags.length === 0 || card.card_flow_tags === null;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = card.card_flow_tags !== undefined && card.card_flow_tags.length > 0;
                            }

                        } else if (condition.selectedField.id_field !== undefined && condition.selectedField.id_field > 0) { //Custom Field                            

                            answer.form_answer_fields.map((answer_field) => {

                                if (answer_field.field_id === condition.selectedField.id_field) {

                                    if (condition.nameComponent === "OPTION_FILTER") {

                                        const answerFieldValue = answer_field.value;
                                        let values = condition.valueOptions;

                                        //If is User Combo Box
                                        if (condition.selectedField !== undefined && condition.selectedField.type !== undefined && condition.selectedField.type === "COMBO_BOX_USER_FIELD") {
                                            //Valid if the values has the -1 value to change to the logged user
                                            if (values !== undefined && values.length > 0 && values.includes("-1")) {
                                                values = values.map((value) => {
                                                    if (value === "-1") {
                                                        return String(user_id);
                                                    }
                                                    return value;
                                                })
                                            }
                                        }

                                        if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                            const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                            if (filterConditions.length >= 1) {
                                                ret = true;
                                            } else if (!findField && ret) {
                                                ret = false;
                                            }
                                        } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                            const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                            if (filterConditions.length >= 1) {
                                                ret = false;
                                            } else {
                                                ret = true;
                                            }
                                        } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                            const filterConditions = values.filter((cond) => answer_field.value === cond);

                                            if (filterConditions.length >= 1) {
                                                ret = true;
                                            } else if (!findField && ret) {
                                                ret = false;
                                            }
                                        } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                            const filterConditions = values.filter((cond) => answer_field.value === cond);

                                            if (filterConditions.length >= 1) {
                                                ret = false;
                                            } else {
                                                ret = true;
                                            }
                                        } else if (condition.selectedComparator === "Está em branco") {
                                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                                        }

                                    } else if (condition.nameComponent === "DATE_FILTER") {

                                        const answerFieldValue = answer_field.valueString.toLocaleLowerCase();
                                        const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                        if (value !== undefined && value.includes("-") && value !== "-4") {

                                            const dynamicFilter = getDynamicDate(value);

                                            if (dynamicFilter.isPeriod && dynamicFilter.endDate !== undefined) {

                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));
                                                const dtStartDate = dynamicFilter.startDate;
                                                const dtEndDate = dynamicFilter.endDate;

                                                ret = (isBefore(dtItemDate, dtEndDate) || isSameDay(dtItemDate, dtEndDate)) && (isAfter(dtItemDate, dtStartDate) || isSameDay(dtItemDate, dtStartDate));

                                            } else {

                                                const dtTargetDate = dynamicFilter.startDate;
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isSameDay(dtItemDate, dtTargetDate);

                                            }

                                        } else if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isSameDay(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                            ret = value !== answerFieldValue;
                                        } else if (condition.selectedComparator === "Está em branco") {
                                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                                        } else if (condition.selectedComparator === "Não  está em branco") {
                                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                        }

                                    } else {                                        

                                        const answerFieldValue = answer_field.valueString.toLocaleLowerCase();
                                        const answerFieldValueNumber = answer_field.value;
                                        const value = condition.value.toLocaleLowerCase();

                                        if (condition.selectedComparator === "Contém" && value !== "") {
                                            ret = answerFieldValue.includes(value);
                                        } else if (condition.selectedComparator === "Não contém" && value !== "") {
                                            ret = !answerFieldValue.includes(value);
                                        } else if (condition.selectedComparator === "Igual a" && value !== "") {
                                            if (answer_field.field.type === 'CURRENCY_FIELD' || answer_field.field.type === 'NUMBER_FIELD') {
                                                ret = Number(answerFieldValueNumber) === Number(value);
                                            } else {
                                                ret = answerFieldValue === value;
                                            }
                                        } else if (condition.selectedComparator === "Não é igual a" && value !== "") {
                                            if (answer_field.field.type === 'CURRENCY_FIELD' || answer_field.field.type === 'NUMBER_FIELD') {
                                                ret = Number(answerFieldValueNumber) !== Number(value);
                                            } else {
                                                ret = answerFieldValue === value;
                                            }
                                        } else if (condition.selectedComparator === "Menor que" && value !== "") {
                                            ret = Number(answerFieldValueNumber) < Number(value);
                                        } else if (condition.selectedComparator === "Maior que" && value !== "") {
                                            ret = Number(answerFieldValueNumber) > Number(value);
                                        } else if (condition.selectedComparator === "Igual a ou maior que" && value !== "") {
                                            ret = Number(answerFieldValueNumber) >= Number(value);
                                        } else if (condition.selectedComparator === "Igual a ou menor que" && value !== "") {
                                            ret = Number(answerFieldValueNumber) <= Number(value);
                                        } else if (condition.selectedComparator === "Está em branco") {
                                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                                        } else if (condition.selectedComparator === "Não está em branco") {
                                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                        }

                                    }

                                    findField = true;

                                }

                                return answer_field;
                            })

                        }

                        return answer;
                    })

                    //If not find the field_id
                    if (!findField) {

                        if (condition.nameComponent === "OPTION_FILTER" || condition.nameComponent === "DATE_FILTER") {

                            if (condition.selectedComparator === "Igual a" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "É uma das" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Antes de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Depois de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou depois" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou antes" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = false;
                            }

                        } else {

                            if (condition.selectedComparator === "Contém" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Maior que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Menor que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou maior que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou menor que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = false;
                            }

                        }

                    }

                    return ret
                })
            }

        }
    }

    return newCards;
}