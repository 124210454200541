export const INBOUND_DOMAIN = process.env.INBOUND_DOMAIN || "inbound.cange.me";

export const URL_BASE = process.env.URL_BASE || "https://mvp.cange.me";

export const URL_FORM_PUBLIC = process.env.URL_BASE || "https://form.cange.me";

export const TEMPLATE_BASE_ID = process.env.TEMPLATE_BASE_ID || 30;

export const PRICING_TABLE_ID = process.env.STRIPE_SECRET_KEY || "prctbl_1PxcccKaAvyw2cp8OTyhBUNy" //"prctbl_1OuXRHKaAvyw2cp8hGtcTdL6";
export const PUBLISHABLE_KEY = process.env.STRIPE_SECRET_KEY || "pk_live_51OpLBwKaAvyw2cp8qdJVEHzbAunXkkbVMrRdFC5YYIRPsSOVYmrpUiIfAnakn1XGZqAmXG3X2Oq3bhEEVRwKOwFv00HHnYVZab" //"pk_test_51OpLBwKaAvyw2cp89aAqIocVTeQMuqn2vGSqIFEABFh69sInYYOB2XqTDODjsahF7NKDFepl1ydeUwgt0wzJmfZy00cpAjxlNl";

export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || "development";