import { format, isAfter, isBefore, isSameDay } from "date-fns";
import { FilterConditionItem } from "../../../dialogs/AutomationField/AddConditionalField";
import { Card } from "../../../interfaces/Card";
import { Conditional } from "../../../interfaces/Conditional";
import convertDMYtoDate from "../../../utils/convertDMYtoDate";
import { FieldProps } from "../Fields/FieldBuilder";

//List Actions
// 1 - Exibir Campo
// 2 - Esconder Campo

export default function getCheckConditionalField(
    field: FieldProps,
    initialData?: object,
    currentData?: object[],
    card?: Card,
): FieldProps {

    let newField: FieldProps = field;

    if (field.updatedByConditional === true && field.propsBase !== undefined) { //If already was changed by conditional, return the field
        newField.show_on_form = field.propsBase.show_on_form;
        newField.required = field.propsBase.required;
        newField.validations = field.propsBase.validations;
    }

    const hasInicialData = initialData !== undefined && initialData !== null && Object.entries(initialData).length > 0;
    const hasCurrentData = currentData !== undefined && currentData !== null && Object.entries(currentData).length > 0;
    const hasCard = card !== undefined && card !== null;

    const objInitialData = initialData !== undefined && initialData !== null ? Object.entries(initialData) : [];
    const objCurrentData = currentData !== undefined && currentData !== null ? Object.entries(currentData) : [];

    if (field.conditionals !== undefined && field.conditionals !== null && field.conditionals.length > 0) {

        let conditional: Conditional;

        const conditionals: Conditional[] = field.conditionals.sort((a, b) => {
            if (a.index === undefined || b.index === undefined) {
                return 0;
            }

            return a.index - b.index
        });

        if (!hasInicialData && !hasCurrentData && !hasCard) { //Form Init - Mount

            //When no have any data to check, get the first conditional
            conditional = conditionals[0];

            if (conditional.action === '1') { //Exibir Campo

                //To restore the field to the default
                newField.updatedByConditional = true;
                newField.propsBase = {
                    show_on_form: field.show_on_form,
                    required: field.required,
                    validations: field.validations
                };

                newField.show_on_form = "S";

            } else if (conditional.action === '2') { //Esconder Campo

                //To restore the field to the default
                newField.updatedByConditional = true;
                newField.propsBase = {
                    show_on_form: field.show_on_form,
                    required: field.required,
                    validations: field.validations
                };

                newField.show_on_form = "N";

            }


        } else {

            let allFilterItems: FilterConditionItem[] = [];
            let values: { name: string, value: object | string, updateLevel: number }[] = [];
            let adicionalValues: { name: string, value: object | string, updateLevel: number }[] = [];

            //UpdateLevel
            //0 - none
            //1 - currentData
            //2 - initValue
            //3 - card

            //Run into all conditionals to get all possible filters to check
            for (let index = 0; index < conditionals.length; index++) {
                const c = conditionals[index];

                if (c.conditions_items !== undefined && c.conditions_items !== null) {
                    const condFilters: FilterConditionItem[] = JSON.parse(c.conditions_items) as FilterConditionItem[];

                    if (condFilters !== undefined && condFilters !== null && condFilters.length > 0) {
                        for (let idxB = 0; idxB < condFilters.length; idxB++) {
                            const f = condFilters[idxB];

                            allFilterItems.push(f);
                        }
                    }
                }
            }

            //Create card object update from current_card, initialValue and current Data
            for (let index = 0; index < allFilterItems.length; index++) {
                const f = allFilterItems[index];

                if (f.selectedField !== undefined) {

                    const alreadyInserted = values.find((v) => v.name === f.selectedField.name);

                    if (alreadyInserted === undefined) {
                        values.push({
                            name: f.selectedField.name,
                            value: "",
                            updateLevel: 0
                        });
                    }
                }
            }

            //Get the current values to the values array
            values = values.map((v) => {

                let findValue = false;

                //Current Data
                if (hasCurrentData) {
                    if (objCurrentData !== undefined && objCurrentData !== null) {
                        for (let idx = 0; idx < objCurrentData.length; idx++) {
                            const obj = objCurrentData[idx];

                            if (obj.length === 2) {
                                if (obj[0] === v.name) {
                                    v.value = obj[1];
                                    v.updateLevel = 1;
                                    findValue = true;
                                }
                            }

                        }
                    }
                }

                //Initial Value
                if (hasInicialData && !findValue) {
                    if (objInitialData !== undefined && objInitialData !== null) {
                        for (let idx = 0; idx < objInitialData.length; idx++) {
                            const obj = objInitialData[idx];

                            if (obj.length === 2) {
                                if (obj[0] === v.name) {
                                    v.value = obj[1];
                                    v.updateLevel = 2;
                                    findValue = true;
                                }
                            }

                        }
                    }
                }

                //Card
                if (hasCard && card !== undefined && !findValue) {

                    //Data de Vencimento
                    if (card.dt_due !== undefined && card.dt_due !== null) {
                        v.value = card.dt_due;
                        v.updateLevel = 3;
                        findValue = true;
                    }

                    //Data de Criação
                    if (card.dt_created !== undefined && card.dt_created !== null) {
                        v.value = card.dt_created;
                        v.updateLevel = 3;
                        findValue = true;
                    }

                    //Responsável
                    if (card.user_id !== undefined && card.user_id !== null) {
                        v.value = String(card.user_id);
                        v.updateLevel = 3;
                        findValue = true;
                    }

                    //Etapa
                    if (card.flow_step_id !== undefined && card.flow_step_id !== null) {
                        v.value = String(card.flow_step_id);
                        v.updateLevel = 3;
                        findValue = true;
                    }

                    if (card.form_answers !== undefined && card.form_answers !== null && card.form_answers.length > 0) {
                        for (let index = 0; index < card.form_answers.length; index++) {
                            const fa = card.form_answers[index];

                            if (fa.form_answer_fields !== undefined && fa.form_answer_fields !== null && fa.form_answer_fields.length > 0) {
                                for (let idx = 0; idx < fa.form_answer_fields.length; idx++) {
                                    const f = fa.form_answer_fields[idx];

                                    if (f.field.name === v.name) {

                                        //Check if inside of the fa has more than one value to the same field
                                        const checkArrayValue = fa.form_answer_fields.filter((f) => f.field.name === v.name);

                                        if (checkArrayValue !== undefined && checkArrayValue.length > 1) {

                                            //Iterate to get the value and put in a array of string
                                            checkArrayValue.map((c) => {

                                                adicionalValues.push({
                                                    name: c.field.name,
                                                    value: c.value,
                                                    updateLevel: 3
                                                });

                                                return c;
                                            });

                                        } else {
                                            v.value = f.value;
                                            v.updateLevel = 3;
                                            findValue = true;
                                        }

                                    }

                                }
                            }

                        }
                    }
                }

                return v;
            });

            //Add the adicional values
            values.push(...adicionalValues);

            //Run into conditions to find the first that match the filters and apply the action
            for (let index = 0; index < conditionals.length; index++) {
                const c = conditionals[index];

                let ret = false;

                if (c.conditions_items !== undefined && c.conditions_items !== null) {
                    const condFilters: FilterConditionItem[] = JSON.parse(c.conditions_items) as FilterConditionItem[];

                    ret = true;

                    for (let idx = 0; idx < condFilters.length; idx++) {
                        const condition = condFilters[idx];

                        //Valid if can keep - Check here
                        const validExec = (condition.valueOptions === undefined || condition.valueOptions.length < 1) && (condition.value === undefined || condition.value === "") && condition.selectedComparator !== "Está em branco" && condition.selectedComparator !== "Não está em branco" ? false : true;

                        if (validExec) {

                            let findField = false;

                            for (let idxB = 0; idxB < values.length; idxB++) {
                                const v = values[idxB];

                                if (condition.selectedField.name === "-1" && v.name === "-1") { //Data de Vencimento

                                    findField = true;

                                    if (typeof v.value === "string" && v.value !== "") {

                                        const answerFieldValue = v.value.toLocaleLowerCase();
                                        const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                        if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isSameDay(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                            ret = value !== answerFieldValue;
                                        } else if (condition.selectedComparator === "Está em branco") {
                                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                                        } else if (condition.selectedComparator === "Não  está em branco") {
                                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                        }

                                    } else if (v.value !== "" && condition.selectedComparator === "Está em branco") {
                                        ret = true;
                                    } else {
                                        ret = false;
                                    }

                                } else if (condition.selectedField.name === "-2" && v.name === "-2") { //Data de Criação

                                    findField = true;

                                    if (typeof v.value === "string" && v.value !== "") {

                                        const answerFieldValue = v.value.toLocaleLowerCase();
                                        const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                        if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isSameDay(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate);

                                        } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                            const dtTargetDate = new Date(convertDMYtoDate(value));
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                        } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                            ret = value !== answerFieldValue;
                                        } else if (condition.selectedComparator === "Está em branco") {
                                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                                        } else if (condition.selectedComparator === "Não  está em branco") {
                                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                        }

                                    } else if (v.value !== "" && condition.selectedComparator === "Está em branco") {
                                        ret = true;
                                    } else {
                                        ret = false;
                                    }

                                } else if (condition.selectedField.name === "-3" && v.name === "-3") { //Responsável

                                    findField = true;

                                    const answerFieldValue = v.value;
                                    const values = condition.valueOptions;

                                    if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "Está em branco") {
                                        ret = answerFieldValue === "" || answerFieldValue === undefined || answerFieldValue === 'null' || answerFieldValue === null;
                                    } else if (condition.selectedComparator === "Não está em branco") {
                                        ret = answerFieldValue !== "" && answerFieldValue !== undefined && answerFieldValue !== 'null' && answerFieldValue !== null;
                                    }

                                } else if (condition.selectedField.name === "-4" && v.name === "-4") { //Etapa 

                                    findField = true;

                                    const answerFieldValue = v.value;
                                    const values = condition.valueOptions;

                                    if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "Está em branco") {
                                        ret = answerFieldValue === "" || answerFieldValue === undefined || answerFieldValue === 'null' || answerFieldValue === null;
                                    } else if (condition.selectedComparator === "Não está em branco") {
                                        ret = answerFieldValue !== "" && answerFieldValue !== undefined && answerFieldValue !== 'null' && answerFieldValue !== null;
                                    }

                                } else if (condition.selectedField.id_field !== undefined && condition.selectedField.id_field > 0) { //Custom Field

                                    if (v.name === condition.selectedField.name) {

                                        if (condition.nameComponent === "OPTION_FILTER") {

                                            const answerFieldValue = v.value;
                                            const values = condition.valueOptions;

                                            if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                                const filterConditions = values.filter((cond) => String(answerFieldValue) === String(cond));

                                                if (filterConditions.length >= 1) {
                                                    ret = true;
                                                } else if (!findField && ret) {
                                                    ret = false;
                                                }

                                                if (!ret && v.value !== undefined && typeof v.value === 'object') {
                                                    const newArr = v.value as string[];

                                                    const filterConditions = values.filter((cond) => newArr.includes(cond));

                                                    if (filterConditions.length >= 1) {
                                                        ret = true;
                                                    } else {
                                                        ret = false;
                                                    }
                                                }

                                            } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                                if (filterConditions.length >= 1) {
                                                    ret = false;
                                                } else {
                                                    ret = true;
                                                }
                                            } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                                if (filterConditions.length >= 1) {
                                                    ret = true;
                                                } else if (!findField && ret) {
                                                    ret = false;
                                                }

                                                if (!ret && v.value !== undefined && typeof v.value === 'object') {
                                                    const newArr = v.value as string[];

                                                    const filterConditions = values.filter((cond) => newArr.includes(cond));

                                                    if (filterConditions.length >= 1) {
                                                        ret = true;
                                                    } else {
                                                        ret = false;
                                                    }
                                                }
                                            } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                                const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                                if (filterConditions.length >= 1) {
                                                    ret = false;
                                                } else {
                                                    ret = true;
                                                }
                                            } else if (condition.selectedComparator === "Está em branco") {
                                                ret = answerFieldValue === "" || answerFieldValue === undefined;
                                            }

                                            findField = true;

                                        } else if (condition.nameComponent === "DATE_FILTER" && typeof v.value === 'string' || typeof v.value === 'object') {                                            

                                            const answerFieldValue = v.value !== undefined && v.value !== null && v.value !== "" ? format(new Date(v.value as string), "dd/MM/yyyy HH:mm") : undefined;
                                            const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                            if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "" && answerFieldValue !== undefined) {

                                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isSameDay(dtItemDate, dtTargetDate);

                                            } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "" && answerFieldValue !== undefined) {

                                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isBefore(dtItemDate, dtTargetDate);

                                            } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "" && answerFieldValue !== undefined) {

                                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isAfter(dtItemDate, dtTargetDate);

                                            } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "" && answerFieldValue !== undefined) {

                                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                            } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "" && answerFieldValue !== undefined) {

                                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                                ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                            } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                                ret = value !== answerFieldValue;
                                            } else if (condition.selectedComparator === "Está em branco") {
                                                ret = answerFieldValue === "" || answerFieldValue === undefined;
                                            } else if (condition.selectedComparator === "Não  está em branco") {
                                                ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                            }

                                            findField = true;

                                        } else if (typeof v.value === 'string' || typeof v.value === 'number') {

                                            const answerFieldValue = String(v.value).toLocaleLowerCase();
                                            const answerFieldValueNumber = v.value;
                                            const value = condition.value.toLocaleLowerCase();

                                            if (condition.selectedComparator === "Contém" && value !== "") {
                                                ret = answerFieldValue.includes(value);
                                            } else if (condition.selectedComparator === "Não contém" && value !== "") {
                                                ret = !answerFieldValue.includes(value);
                                            } else if (condition.selectedComparator === "Igual a" && value !== "") {
                                                if (condition.selectedField.type === 'CURRENCY_FIELD' || condition.selectedField.type === 'NUMBER_FIELD') {
                                                    ret = Number(answerFieldValueNumber) === Number(value);
                                                } else {
                                                    ret = answerFieldValue === value;
                                                }
                                            } else if (condition.selectedComparator === "Não é igual a" && value !== "") {
                                                if (condition.selectedField.type === 'CURRENCY_FIELD' || condition.selectedField.type === 'NUMBER_FIELD') {
                                                    ret = Number(answerFieldValueNumber) !== Number(value);
                                                } else {
                                                    ret = answerFieldValue === value;
                                                }
                                            } else if (condition.selectedComparator === "Menor que" && value !== "") {
                                                ret = Number(answerFieldValueNumber) < Number(value);
                                            } else if (condition.selectedComparator === "Maior que" && value !== "") {
                                                ret = Number(answerFieldValueNumber) > Number(value);
                                            } else if (condition.selectedComparator === "Igual a ou maior que" && value !== "") {
                                                ret = Number(answerFieldValueNumber) >= Number(value);
                                            } else if (condition.selectedComparator === "Igual a ou menor que" && value !== "") {
                                                ret = Number(answerFieldValueNumber) <= Number(value);
                                            } else if (condition.selectedComparator === "Está em branco") {
                                                ret = answerFieldValue === "" || answerFieldValue === undefined;
                                            } else if (condition.selectedComparator === "Não está em branco") {
                                                ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                            }

                                            findField = true;

                                        }

                                    }

                                }

                            }

                            //If not find the field_id
                            if (!findField) {

                                if (condition.nameComponent === "OPTION_FILTER" || condition.nameComponent === "DATE_FILTER") {

                                    if (condition.selectedComparator === "Igual a" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "É uma das" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Antes de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Depois de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Igual a ou depois" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Igual a ou antes" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Não está em branco") {
                                        ret = false;
                                    }

                                } else {

                                    if (condition.selectedComparator === "Contém" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Igual a" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Maior que" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Menor que" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Igual a ou maior que" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Igual a ou menor que" && condition.value !== "") {
                                        ret = false;
                                    } else if (condition.selectedComparator === "Não está em branco") {
                                        ret = false;
                                    }

                                }

                            }

                        }

                    }

                }

                if (ret) {

                    if (c.action === '1') { //Exibir Campo

                        //To restore the field to the default
                        newField.updatedByConditional = true;
                        newField.propsBase = {
                            show_on_form: field.show_on_form,
                            required: field.required,
                            validations: field.validations
                        };

                        newField.show_on_form = "N";

                    } else if (c.action === '2') { //Esconder Campo

                        //To restore the field to the default
                        newField.updatedByConditional = true;
                        newField.propsBase = {
                            show_on_form: field.show_on_form,
                            required: field.required,
                            validations: field.validations
                        };

                        newField.show_on_form = "S";
                        newField.required = false;
                        newField.validations = [];

                    }

                    break;
                } else { // If the ret is false

                    if (c.action === '1') { //Exibir Campo

                        //To restore the field to the default
                        newField.updatedByConditional = true;
                        newField.propsBase = {
                            show_on_form: field.show_on_form,
                            required: field.required,
                            validations: field.validations
                        };

                        newField.show_on_form = "S";
                        newField.required = false;
                        newField.validations = [];

                    } else if (c.action === '2') { //Esconder Campo

                        //To restore the field to the default
                        newField.updatedByConditional = true;
                        newField.propsBase = {
                            show_on_form: field.show_on_form,
                            required: field.required,
                            validations: field.validations
                        };

                        newField.show_on_form = "N";

                    }

                }

            }
        }

    }

    return newField;

}