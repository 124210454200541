import styled, { css } from "styled-components";

interface AutomationConfigItemIconProps {
    color: string;
}

interface AutomationConfigItemProps {
    color: string;
}

export const Container = styled.div`
    width: 100%;
`;

export const ContainerConfigRightHeader = styled.div`
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;    
    position: relative;
`;

export const ContainerConfigRightHeaderTitle = styled.div`

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const ContainerConfigRightHeaderSearch = styled.div`
`;

export const ContainerCloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 20px;
`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const AutomationConfigGroup = styled.div`
    padding: 15px;
    padding-bottom: 0px;
    width: 100%;
`;

export const AutomationConfigTitle = styled.div`
    font-size: 13px;
    color: #4d4d4d;
    margin-bottom: 2px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #f4f5f7;
    margin-bottom: 15px;
`;

export const AutomationConfigItems = styled.div`
    
`;

export const AutomationConfigItem = styled.div<AutomationConfigItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    :hover {
        background-color: #f23b5d1e;

        ${props => props.color && css`
            background-color: ${props.color}1e;
        `}
    }
`;

export const AutomationConfigItemIcon = styled.div<AutomationConfigItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    min-height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AutomationConfigItemDescription = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
        font-size: 14px;
        color: black;
        margin-bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #00bcd4;
            color: white;
            border-radius: 10px;
            margin-left: 5px;
            padding: 3px 7px;
            font-size: 10px;
        }
    }

    span {
        font-size: 12px;
        color: grey;
    }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 75px;
    }
    
`;