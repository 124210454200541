import {
    CellDue,
    CellStep,
    DataTableCange,
    ListViewContainer,
    LoaderContainer,
    NoRegister,
    RegisterData,
    TaskHeaderLeft,
    TaskTag,
    AggregationContainer,
    AggregationItem,
    AggregateItemLeft,
    AggregationName,
    AggregationNameType,
    AggregationValue,
    AggregateItemRight,
    AggregateConfig,
    AggregationNameText,
    ArchevedDetail,
    LinkData,
    LinkDataUrl,
    TitleCell
} from "./style";
import { BsFillArchiveFill, BsFillGearFill } from "react-icons/bs";
import { FaExternalLinkAlt, FaMailBulk } from "react-icons/fa";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../interfaces/Card";
import { CardFlowTag } from '../../interfaces/CardFlowTag';
import ContentLoader from "react-content-loader"
import EmptyCard from '../EmptyState/EmptyCard';
import EmptyStep from '../EmptyState/EmptyStep';
import { FieldProps, getMaskField } from "../Forms/Fields/FieldBuilder";
import { FieldViewProps, FilterFlowProps } from '../FilterBar/FilterDialog';
import { Flow } from "../../interfaces/Flow";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import api from '../../services/api';
import getCardFiltered from '../../middlewares/getCardFiltered';
import getCardSorted from "../../middlewares/getCardSorted";
import getFields from "../../utils/getFields";
import updateFormulaFromCard from "../../utils/Formula/updateFormulaFromCard";
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { UpdateControlProps } from "../../pages/Flow";
import getCardAggregations from "../../middlewares/getCardAggregations";
import { FormAnswer } from "../../interfaces/FormAnswer";
import AvatarCange from "../AvatarCange";
import { User } from "../../interfaces/User";
import CheckListView from "../Forms/Fields/Components/CheckListField/CheckListView";
import ComboBoxFlowProgressView from "../Forms/Fields/Components/ComboBoxFlow/ComboBoxFlowProgressView";
import ComboBoxFlowStepView from "../Forms/Fields/Components/ComboBoxFlow/ComboBoxFlowStepView";
import { useAuth } from "../../hooks/auth";
import BulkActionsBar from "../BulkActionsBar";
import getAccessControl from "../../middlewares/AccessControl";
import TableQuickAction from "../TableQuickAction";

interface TableCangeProps {
    selectedValue: Flow;
    updateControl?: UpdateControlProps;
    testModel?: boolean;
    filterFlow?: FilterFlowProps;
    printComponentRef?: React.MutableRefObject<null>;
    isArchived?: boolean;
    typeUser: string;
    setUpdateControl: React.Dispatch<React.SetStateAction<UpdateControlProps>>
    onRowClicked: (row: Card) => Promise<void>;
    configCard?: () => Promise<void>;
}

const customStyles = {
    headRow: {
        style: {
            borderTop: 'solid 0.5px #e0e0e0'
        },
    },
    headCells: {
        style: {
            color: 'black',
            fontSize: '14px',
            //fontWeight: 400,
            borderBottom: 'solid 0.5px #e0e0e0',
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0',

        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: '#f4f4f4',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid white',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
    cells: {
        style: {
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0'
        }
    },
};

interface ColumnSizeProps {
    id_field: number;
    width: number;
}

interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

const LoaderTable = () => (
    <ContentLoader
        speed={2}
        height={500}
        width={'100%'}
        backgroundColor="#ffffff"
        foregroundColor="#f4f5f7"
    >
        <rect x="0" y="0" rx="0" ry="0" width="350px" height="55px" />
        <rect x="355" y="0" rx="0" ry="0" width="200px" height="55px" />
        <rect x="560" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="745" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="930" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1115" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1300" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1485" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1670" y="0" rx="0" ry="0" width="4000px" height="55px" />

        <rect x="0" y="60" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="115" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="170" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="225" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="280" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="335" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="390" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="445" rx="0" ry="0" width="4000px" height="50px" />
    </ContentLoader>
)

const TableCange: React.FC<TableCangeProps> = ({ typeUser, selectedValue, onRowClicked, testModel, filterFlow, updateControl, configCard, printComponentRef, isArchived, setUpdateControl }) => {

    const { addToast } = useToast();
    const { user } = useAuth();

    const [subHeaderAlign] = useState('right');
    const [noHeader] = useState(true);
    const [columnConfig, setColumnConfig] = useState<IDataTableColumn[]>();
    const [cards, setCards] = useState<Card[]>();
    const [cardsFiltered, setCardsFiltered] = useState<Card[]>();
    const [cardsSelected, setCardsSelected] = useState<Card[]>();
    const [aggregateItems, setAggregateItems] = useState<AggregateItemsProps[]>([]);
    const history = useHistory();

    /* Loading Variables */
    const [loading, setLoading] = useState<boolean>(true);

    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const goToEditFlow = useCallback(async (id_step?: number) => {

        if (selectedValue.hash !== undefined && id_step === undefined) {
            history.push('/flow/3/' + selectedValue.hash + '/edit');
        } else if (selectedValue.hash !== undefined && id_step !== undefined) {
            history.push('/flow/3/' + selectedValue.hash + '/edit/' + id_step);
        }

    }, [selectedValue.hash, history]);

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const handleCloseBulkActions = useCallback(async () => {
        setCardsSelected([]);
        handleClearRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleForceUpdateBulkActions = useCallback(async (id_card_items?: number[], isUpdateAll?: boolean) => {

        if (id_card_items !== undefined && id_card_items !== null && id_card_items.length > 0) {

            await api.post(`/card/by-cards`, {
                card_items: id_card_items,
                flow_id: selectedValue.id_flow,
                isIgnoringDelArchStatus: true,
                isWithPreAnswer: true
            }).then(response => {
                if (response.data !== null) {

                    const newCards: Card[] = response.data;

                    if (isUpdateAll) {
                        setUpdateControl({
                            random: (Math.random() * 100) * -1,
                        });
                    } else {
                        setUpdateControl({
                            random: (Math.random() * 100) * -1,
                            cards: newCards
                        });
                    }

                }
            });

        }

    }, [selectedValue.id_flow, setUpdateControl]);

    const getColumnsConfig = useCallback((columnsSize?: ColumnSizeProps[], fieldView?: FieldViewProps[]) => {

        let newColumnConfig: IDataTableColumn[] = [];

        let isControledByFieldView = false;
        if (fieldView !== undefined && fieldView.length > 0) {
            isControledByFieldView = true;
        }

        const columnWidthTitle = columnsSize?.filter((col) => col.id_field === -6);
        const columnWidthStep = columnsSize?.filter((col) => col.id_field === -5);
        const columnWidthTags = columnsSize?.filter((col) => col.id_field === -4);

        //Fields Default
        const idTitle = -6;
        const colTitle: IDataTableColumn = {
            id: idTitle,
            name: "Título",
            cell: (row) => {
                return (
                    <TitleCell onClick={() => onRowClicked(row)}>
                        {row.title}
                    </TitleCell>
                )
            },
            minWidth: columnWidthTitle !== undefined && columnWidthTitle.length > 0 ? columnWidthTitle[0].width + "px" : "175px",
            allowOverflow: true
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idTitle);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colTitle);
                }
            }
        } else {
            newColumnConfig.push(colTitle);
        }

        //Fields Default
        const idStep = -5;
        const colStep: IDataTableColumn = {
            id: idStep,
            name: "Etapa",
            cell: (row) => {
                return (
                    <>
                        {row.archived === "S" ? <ArchevedDetail><BsFillArchiveFill /></ArchevedDetail> : <></>}
                        <CellStep onClick={() => onRowClicked(row)} color={row.flow_step?.color}>
                            {row.flow_step?.icon !== undefined ?
                                <IconPickerItem
                                    icon={row.flow_step?.icon as IconList}
                                    color={"white"}
                                /> :
                                <FaMailBulk />
                            }
                            {row.flow_step?.name}
                        </CellStep>
                    </>
                )
            },
            minWidth: columnWidthStep !== undefined && columnWidthStep.length > 0 ? columnWidthStep[0].width + "px" : "175px",
            center: true,
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idStep);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colStep);
                }
            }
        } else {
            newColumnConfig.push(colStep);
        }


        //Fields Default
        const idTag = -4;
        const colTag: IDataTableColumn = {
            id: idTag,
            name: "Etiquetas",
            cell: (row) => {
                return (
                    <TableQuickAction
                        type="tag"
                        flow_id={selectedValue.id_flow}
                        card_id={row.id_card}
                        isSelected={row.card_flow_tags !== undefined && row.card_flow_tags.length > 0}
                        onClose={handleCloseBulkActions}
                        onForceUpdate={handleForceUpdateBulkActions}
                    >
                        {row.card_flow_tags !== undefined && row.card_flow_tags.length > 0 && (
                            <TaskHeaderLeft>
                                {row.card_flow_tags?.map((tag: CardFlowTag) => {
                                    return (
                                        <TaskTag key={tag.id_card_flow_tag} color={tag.flow_tag.color}>{tag.flow_tag.description}</TaskTag>
                                    );
                                })}
                            </TaskHeaderLeft>
                        )}
                    </TableQuickAction>
                )
            },
            minWidth: columnWidthTags !== undefined && columnWidthTags.length > 0 ? columnWidthTags[0].width + "px" : "175px",
            center: true,
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idTag);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colTag);
                }
            }
        } else {
            newColumnConfig.push(colTag);
        }

        //Fields Default
        const idAssigned = -3;
        const colAssigned: IDataTableColumn = {
            id: idAssigned,
            name: "Responsável",
            cell: (row) => {
                return (
                    <TableQuickAction
                        type="user"
                        flow_id={selectedValue.id_flow}
                        card_id={row.id_card}
                        isSelected={row.user !== undefined}
                        onClose={handleCloseBulkActions}
                        onForceUpdate={handleForceUpdateBulkActions}
                    >
                        {row.user !== undefined && (
                            <AvatarCange user={row.user as User} size="35" />
                        )}
                    </TableQuickAction>
                )
            },
            minWidth: "55px",
            center: true
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idAssigned);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colAssigned);
                }
            }
        } else {
            newColumnConfig.push(colAssigned);
        }

        //Fields Default
        const idDue = -2;
        const colDue: IDataTableColumn = {
            id: idDue,
            name: "Vencimento",
            cell: (row) => {

                //complete: null // complete: "S"
                //status_dt_due: 3
                //0 - Vencido / 1 - Vence Hoje / 2 = Vence Amanhã / 3 = Vence depois ou Sem dt Due

                let colorDue;
                let label = row.dt_due_string;

                if (row.status_dt_due !== undefined) {
                    if (row.status_dt_due === 0) {
                        colorDue = "#fe4941";
                    } else if (row.status_dt_due === 1) {
                        colorDue = "#60bd4f";
                        label = "Hoje";
                    } else if (row.status_dt_due === 2) {
                        colorDue = "#60bd4f";
                        label = "Amanhã";
                    } else if (row.status_dt_due === 3) {
                        colorDue = "gray";
                    }
                }

                return (
                    <TableQuickAction
                        type="due_date"
                        flow_id={selectedValue.id_flow}
                        card_id={row.id_card}
                        isSelected={row.dt_due !== undefined && row.dt_due !== null}
                        onClose={handleCloseBulkActions}
                        onForceUpdate={handleForceUpdateBulkActions}
                    >
                        {row.dt_due !== undefined && row.dt_due !== null && (
                            row.complete !== undefined && row.complete === "S" ?
                                <CellDue color={colorDue}><s>{label}</s></CellDue> :
                                <CellDue color={colorDue}>{label}</CellDue>
                        )}
                    </TableQuickAction>
                )
            },
            minWidth: "150px",
            center: true
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idDue);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colDue);
                }
            }
        } else {
            newColumnConfig.push(colDue);
        }

        //Fields Default
        const idCreated = -1;
        const colCreated: IDataTableColumn = {
            id: idCreated,
            name: "Criação",
            cell: (row) => {

                let colorDue = "gray";
                let label = row.dt_created;

                if (row.dt_created !== undefined && row.dt_created !== null) {
                    const date = new Date(row.dt_created);
                    label = date.toLocaleDateString('pt-BR');
                }

                return (
                    <CellDue onClick={() => onRowClicked(row)} color={colorDue}>{label}</CellDue>
                )
            },
            minWidth: "150px",
            center: true
        }
        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
            const idx = fieldView.findIndex((fv) => fv.id_field === idCreated);
            if (idx !== undefined && fieldView[idx] !== undefined) {
                if (fieldView[idx].active) {
                    newColumnConfig.push(colCreated);
                }
            }
        } else {
            newColumnConfig.push(colCreated);
        }

        if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {

            const fieldViewActive = fieldView.filter((fv) => fv.active);

            if (fieldViewActive !== undefined && fieldViewActive.length > 0) {
                for (let index = 0; index < fieldViewActive.length; index++) {
                    const fv = fieldViewActive[index];

                    //Find the field object
                    let fieldObj = selectedValue.form_init?.fields.find((field) => field.id_field === fv.id_field);
                    if (fieldObj === undefined) {
                        for (let indexStep = 0; indexStep < selectedValue.flow_steps.length; indexStep++) {
                            const s = selectedValue.flow_steps[indexStep];
                            const foundField = s.form.fields.find((f) => f.id_field === fv.id_field);
                            if (foundField) {
                                fieldObj = foundField;
                                break;
                            }
                        }
                    }

                    if (fieldObj !== undefined && fieldObj.id_field !== undefined) {
                        const columnWidth = columnsSize?.filter((col) => col.id_field === fv.id_field);

                        const column: IDataTableColumn = {
                            id: fv.id_field,
                            name: fv.title.toString(),
                            cell: (row) => {

                                let value: FormAnswerField[] | undefined = undefined;

                                if (row.form_answers !== undefined && row.form_answers.length > 0) {
                                    //Filter the form answer with the same form_id
                                    const formAnswer = row.form_answers.filter((answer: FormAnswer) => answer.form_id === fv.form_id);

                                    //Get the form answer with the biggest id
                                    let newerFormAnswer: FormAnswer | undefined = undefined;
                                    if (formAnswer !== undefined && formAnswer.length > 1) {
                                        newerFormAnswer = formAnswer.reduce((prev: FormAnswer, current: FormAnswer) => (prev.id_form_answer > current.id_form_answer) ? prev : current);
                                    } else {
                                        newerFormAnswer = formAnswer[0];
                                    }

                                    //Get the form answer field with the same field_id
                                    if (newerFormAnswer !== undefined && newerFormAnswer.form_answer_fields !== undefined && newerFormAnswer.form_answer_fields.length > 0) {
                                        value = newerFormAnswer.form_answer_fields.filter((answer: FormAnswerField) => answer.field_id === fv.id_field);
                                    }
                                } else {
                                    value = undefined;
                                }

                                const isMultiple = fv.type === "COMBO_BOX_REGISTER_FIELD" || fv.type === "COMBO_BOX_FLOW_FIELD" || fv.type === "INPUT_LIST_FIELD" || fv.type === "CHECK_BOX_FIELD" || fv.type === "COMBO_BOX_FIELD" ? true : false;

                                return (

                                    value === undefined || value.length <= 0 ?
                                        <NoRegister>Sem registro</NoRegister> :

                                        fv.type === "CHECK_LIST_FIELD" ?
                                            <CheckListView key={-1} value={value} onlyPercent /> :
                                            fv.type === "COMBO_BOX_FLOW_FIELD" && String(fv.maxLength) === "2" && value.length >= 1 ?
                                                <ComboBoxFlowProgressView key={-1} value={value} /> :
                                                fv.type === "COMBO_BOX_FLOW_FIELD" && value.length >= 1 ?
                                                    <ComboBoxFlowStepView key={-1} value={value} /> :
                                                    fv.type === "LINK_FIELD" && value[0] !== undefined ?
                                                        <a key={index} href={value[0].value} target="_blank" rel="noreferrer">
                                                            <LinkData key={index} color={"#4681b81e"}>
                                                                <LinkDataUrl>
                                                                    {value[0].value}
                                                                </LinkDataUrl>
                                                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px' }} />
                                                            </LinkData>
                                                        </a> :

                                                        value.map((val, index) => {
                                                            return (
                                                                isMultiple && val.relatedOrigin !== undefined && val.relatedOrigin === 'register' ?
                                                                    <Link key={index} to={"/register/" + val.relatedOriginHash + "/register/" + val.relatedId} style={{ textDecoration: 'none' }} target="_blank">
                                                                        <RegisterData key={index} color={val.relatedOriginColor}>
                                                                            {val.valueString}
                                                                            <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px' }} />
                                                                        </RegisterData>
                                                                    </Link> :
                                                                    isMultiple && val.relatedOrigin !== undefined && val.relatedOrigin === 'flow' ?
                                                                        <Link key={index} to={"/flow/" + val.relatedOriginHash + "/card/" + val.relatedId} style={{ textDecoration: 'none' }} target="_blank">
                                                                            <RegisterData key={index} color={val.relatedOriginColor}>
                                                                                {val.valueString}
                                                                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px' }} />
                                                                            </RegisterData>
                                                                        </Link> :
                                                                        <div key={index} onClick={() => onRowClicked(row)}>
                                                                            {isMultiple ?
                                                                                <RegisterData key={index}>{val.valueString}</RegisterData> :
                                                                                <div key={val.id_form_answer_field + val.index}>{val.valueString}</div>
                                                                            }
                                                                        </div>
                                                            )
                                                        })

                                )
                            },
                            minWidth: columnWidth !== undefined && columnWidth.length > 0 ? columnWidth[0].width + "px" : "175px",
                            allowOverflow: false,
                            center: fv.type === "DATE_PICKER_FIELD" || fv.type === "DUE_DATE_FIELD" ? true : false
                        }

                        newColumnConfig.push(column);

                    }

                }
            }

            //Order the array
            newColumnConfig = newColumnConfig.sort((a: IDataTableColumn, b: IDataTableColumn) => {

                const findPositionA = fieldView.find((f) => f.id_field === a.id);
                const findPositionB = fieldView.find((f) => f.id_field === b.id);

                if (findPositionA !== undefined && findPositionB !== undefined) {
                    return findPositionA.index - findPositionB.index;
                }

                if (findPositionA === undefined) {
                    return 1;
                }

                if (findPositionB === undefined) {
                    return -1;
                }

                return 0;
            });
        }

        return newColumnConfig;

    }, [onRowClicked, selectedValue, handleCloseBulkActions, handleForceUpdateBulkActions]);

    const handleColumnsSize = useCallback(() => {

        //Get the size of columns
        let columnsSize: ColumnSizeProps[] = [];
        let sizeByPixel = 8; //7.13

        if (filterFlow !== undefined && filterFlow.fieldView !== undefined && filterFlow.fieldView.length > 0) {

            if (cards !== undefined && cards.length > 0) {

                //Title
                const idTitle = -6;
                const maxSizeTitle = 400;
                const minSizeTitle = 175;

                //Tags
                const idTags = -4;
                const maxSizeTags = 400;
                const minSizeTags = 30;
                const plusSpaceTags = 3;

                //Step
                const idStep = -5;
                const maxSizeStep = 350;
                const minSizeStep = 175;

                for (let indexField = 0; indexField < filterFlow.fieldView.length; indexField++) {
                    const fv = filterFlow.fieldView[indexField];

                    if (fv.id_field === idTitle) { //Title

                        const maxLength = cards.reduce((max, card) => {
                            const stringLength = card.title !== undefined && card.title !== null ? card.title.length : 0;
                            return stringLength > max ? stringLength : max;
                        }, 0);

                        const sizeColumn = maxLength * (sizeByPixel + 1);

                        columnsSize.push({
                            id_field: fv.id_field,
                            width: sizeColumn < minSizeTitle ? minSizeTitle : sizeColumn > maxSizeTitle ? maxSizeTitle : sizeColumn
                        } as ColumnSizeProps);

                    } else if (fv.id_field === idTags) { //Tags

                        const maxLength = cards.reduce((max, card) => {
                            const stringLength = card.card_flow_tags !== undefined && card.card_flow_tags.length > 0 ? card.card_flow_tags.length * 10 : 0;
                            return stringLength > max ? stringLength : max;
                        }, 0);

                        const sizeColumn = (maxLength + plusSpaceTags) * (sizeByPixel);

                        columnsSize.push({
                            id_field: fv.id_field,
                            width: sizeColumn < minSizeTags ? minSizeTags : sizeColumn > maxSizeTags ? maxSizeTags : sizeColumn
                        } as ColumnSizeProps);


                    } else if (fv.id_field === idStep) { //Step

                        const maxLength = cards.reduce((max, card) => {
                            const stringLength = card.flow_step !== undefined && card.flow_step.name !== undefined ? card.flow_step.name.length : 0;
                            return stringLength > max ? stringLength : max;
                        }, 0);

                        const sizeColumn = maxLength * (sizeByPixel + 3);

                        columnsSize.push({
                            id_field: fv.id_field,
                            width: sizeColumn < minSizeStep ? minSizeStep : sizeColumn > maxSizeStep ? maxSizeStep : sizeColumn
                        } as ColumnSizeProps);
                    } else {

                        const plusSpace: number = fv.type === "COMBO_BOX_REGISTER_FIELD" || fv.type === "COMBO_BOX_FLOW_FIELD" ? 60 : 0;
                        const maxSize = 400;
                        const minSize = 175;
                        let countFields = 1;

                        const maxValueStringLength = cards.reduce((max, card) => {
                            if (card.form_answers !== undefined && card.form_answers.length > 0) {
                                const fa = card.form_answers.filter((faf) => faf.form_id === fv.form_id).sort((a, b) => b.id_form_answer - a.id_form_answer);

                                if (fa !== undefined && fa.length > 0) {

                                    const faf = fa[0].form_answer_fields.filter((faf) => faf.field_id === fv.id_field);

                                    countFields = faf.length >= countFields ? faf.length : countFields;

                                    if (faf.length >= 2) {
                                        countFields = 2;
                                    }

                                    if (faf !== undefined && faf.length > 0) {
                                        const valueStringLength = faf[0] !== undefined && faf[0].valueString !== undefined ? faf[0].valueString.length : 0;

                                        return valueStringLength > max ? valueStringLength : max;
                                    }
                                }
                            }
                            return max;
                        }, 0);

                        let sizeColumn = maxValueStringLength !== undefined ? maxValueStringLength * sizeByPixel : 100;
                        sizeColumn = sizeColumn < minSize ? minSize : sizeColumn > maxSize ? maxSize : sizeColumn;

                        let finalWidth = (sizeColumn * countFields) + plusSpace;

                        //Default Value
                        if (fv.type === "CHECK_LIST_FIELD") {
                            finalWidth = 300;
                        } else if (fv.type === "COMBO_BOX_FLOW_FIELD") {
                            finalWidth = 400;
                        }

                        columnsSize.push({
                            id_field: fv.id_field,
                            width: finalWidth
                        } as ColumnSizeProps);

                    }

                }
            }

        }

        if (columnsSize !== undefined && columnsSize.length > 0) {
            const columnConfig = getColumnsConfig(columnsSize, filterFlow?.fieldView);
            return columnConfig;
        }

        return undefined;

    }, [cards, getColumnsConfig, filterFlow]);

    const getAggregations = useCallback(async (newCards: Card[]) => {

        if (selectedValue.fields_aggregate !== undefined && selectedValue.fields_aggregate.length >= 0) {
            const newAggregation = getCardAggregations(selectedValue.fields_aggregate, newCards);
            setAggregateItems(newAggregation);
        }

    }, [selectedValue.fields_aggregate]);

    const getApiData = useCallback(async () => {

        setLoading(true);

        try {

            if (selectedValue !== undefined && selectedValue.id_flow > 0
                && selectedValue.flow_steps !== undefined && selectedValue.flow_steps.length > 0) {

                const response = await api.get(`/card/by-flow`, {
                    params: {
                        flow_id: selectedValue.id_flow,
                        isTestModel: testModel !== undefined ? testModel : undefined,
                        isArchived: isArchived !== undefined ? isArchived : undefined,
                        isWithPreAnswer: 'true'
                    }
                });

                if (response.data !== null) {
                    let cardsApi: Card[] = response.data;
                    setCards(cardsApi);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao abrir o Fluxo',
                description: 'Ocorreu ao tentar abrir o Fluxo!',
            });
        }
    }, [addToast, selectedValue, testModel, isArchived]);

    const handleSelectRows = (object: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: any[];
    }) => {

        if (object.selectedRows !== undefined) {
            setCardsSelected(object.selectedRows);

            if (toggledClearRows) {
                setToggleClearRows(!toggledClearRows);
            }
        }

    };

    useEffect(() => {

        const requireUpdate = (updateControl?.card !== undefined && updateControl.card.id_card > 0) || (updateControl?.cards !== undefined && updateControl.cards.length > 0);

        if (requireUpdate) {

            let cardsToUpdate: Card[] = [];

            if (updateControl !== undefined && updateControl.cards !== undefined) {
                cardsToUpdate = updateControl.cards;
            }

            if (updateControl !== undefined && updateControl.card !== undefined) {
                cardsToUpdate.push(updateControl.card);
            }

            for (let index = 0; index < cardsToUpdate.length; index++) {

                const newCard = cardsToUpdate[index];

                setCards((prevCards: Card[]) => {

                    const updatedCards = [...prevCards];

                    const index = updatedCards.findIndex((card) => card.id_card === newCard.id_card);

                    let action: 'remove' | 'update' | 'add' = 'update';

                    if (!isArchived) { //Regular Flow
                        if (index !== -1) {
                            if (newCard.archived === "S") {
                                action = 'remove';
                            } else if (newCard.deleted === "S") {
                                action = 'remove';
                            } else {
                                action = 'update';
                            }
                        } else {
                            if (newCard.archived !== "S" && newCard.deleted !== "S") {
                                action = 'add';
                            }
                        }
                    } else {
                        if (index !== -1) {
                            if (newCard.archived === "S" && newCard.deleted !== "S") {
                                action = 'update';
                            } else {
                                action = 'remove';
                            }
                        } else {
                            if (newCard.archived === "S" && newCard.deleted !== "S") {
                                action = 'add';
                            }
                        }
                    }

                    if (action === 'update') {
                        updatedCards[index] = newCard;
                    } else if (action === 'add') {
                        updatedCards.push(newCard);
                    } else if (action === 'remove') {
                        updatedCards.splice(index, 1);
                    }

                    return updatedCards;
                });

            }

        } else if (selectedValue !== undefined && selectedValue.id_flow > 0) {
            getApiData();
        }

    }, [selectedValue, getApiData, testModel, updateControl, isArchived]);

    useEffect(() => {

        let newCards: Card[] | undefined = cards;

        if (newCards !== undefined && filterFlow !== undefined) {

            newCards = getCardFiltered(newCards, user.id_user, filterFlow.conditions, filterFlow.searchText);
            newCards = getCardSorted(newCards, filterFlow.orderBy);

            let fields: FieldProps[] = [];
            if (selectedValue.form_init !== undefined && selectedValue.flow_steps !== undefined) {
                fields = getFields(selectedValue.form_init, selectedValue.flow_steps);
            }

            newCards = newCards.map((card) => {
                return updateFormulaFromCard(card, fields);
            });

            const newColumnConfig = handleColumnsSize();

            getAggregations(newCards);

            setColumnConfig(newColumnConfig);
            setCardsFiltered(newCards);

        }

    }, [filterFlow, cards, handleColumnsSize, selectedValue, getAggregations, user.id_user]);

    return (
        selectedValue.flow_steps.length > 0 ?
            loading ?
                <LoaderContainer>
                    <LoaderTable />
                </LoaderContainer> :
                columnConfig !== undefined && cardsFiltered !== undefined ?
                    <>
                        <div ref={printComponentRef}>
                            {/* Aggregation */}
                            {aggregateItems !== undefined && aggregateItems.length > 0 ?
                                <AggregationContainer>
                                    {aggregateItems.map((item) => (
                                        <AggregationItem key={item.columnName + item.typeField} color={selectedValue !== undefined && selectedValue.color !== undefined ? selectedValue.color : "#9e37ed"}>
                                            <AggregateItemLeft>
                                                <AggregationName>
                                                    <AggregationNameText>{item.columnName}</AggregationNameText>
                                                    <AggregationNameType>
                                                        {"(" + item.typeAggregate + ")"}
                                                    </AggregationNameType>
                                                </AggregationName>
                                                <AggregationValue>
                                                    {item.value !== undefined ? getMaskField(item.typeField, item.value, item.variation) : "0"}
                                                </AggregationValue>
                                            </AggregateItemLeft>
                                            <AggregateItemRight>
                                                <AggregateConfig className="aggregateConfig" onClick={() => configCard !== undefined ? configCard() : {}}>
                                                    <BsFillGearFill />
                                                </AggregateConfig>
                                            </AggregateItemRight>
                                        </AggregationItem>
                                    ))}
                                </AggregationContainer> : <></>
                            }

                            <ListViewContainer>
                                <DataTableCange
                                    className="tableCange"
                                    columns={columnConfig}
                                    data={cardsFiltered}
                                    subHeaderAlign={subHeaderAlign}
                                    noHeader={noHeader}
                                    customStyles={customStyles}
                                    striped={true}
                                    onRowClicked={onRowClicked}
                                    highlightOnHover={true}
                                    noDataComponent={<div style={{ color: 'black', margin: '10px', padding: '20px', fontWeight: '500' }}>Não encontramos nenhum registro com os filtros escolhidos!</div>}
                                    pointerOnHover
                                    sortServer={false}
                                    /* Selectable Rows */
                                    selectableRows={getAccessControl(92, typeUser)}
                                    onSelectedRowsChange={getAccessControl(92, typeUser) ? handleSelectRows : undefined}
                                    clearSelectedRows={toggledClearRows}
                                    color={selectedValue !== undefined && selectedValue.color !== undefined ? selectedValue.color : "#9e37ed"}
                                />
                                <div style={{ height: '10px', marginBottom: '10px' }} />
                            </ListViewContainer>

                            {getAccessControl(92, typeUser) && selectedValue !== undefined && selectedValue.id_flow !== undefined && (
                                <BulkActionsBar
                                    flow_id={selectedValue.id_flow}
                                    cardsSelected={cardsSelected}
                                    onClose={handleCloseBulkActions}
                                    onForceUpdate={handleForceUpdateBulkActions}
                                />
                            )}
                        </div>
                    </> :
                    <><EmptyCard /></> :
            <div onClick={() => goToEditFlow()}><EmptyStep /></div>

    );

}

export default TableCange;