import styled, { css } from "styled-components";
import Button from "../../../components/Button";

interface ContainerConfigProps {
    isSplited?: boolean;
}

interface BlockAutomationProps {
    color?: string;
    isSelected?: boolean;
}

interface BlockConditionalActionProps {
    color?: string;
    isSelected?: boolean;
}

interface BlockAutomationLeftProps {
    color?: string;
}

interface BlockConditionalActionHeaderProps {
    color?: string;
}

export const Container = styled.div`

    .app-bar-header-left {
        width: 100%;

        .app-bar-header-left-nevigation {
            width: 100%;

            input {
                margin-right: 20px;
                width: 100%;
            }

        }
    }
`;

export const ButtonSave = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    max-width: 185px !important;
    width: auto !important;

    font-size: 15px;
    height: 35px !important;

    div {
        margin-top: 3px !important;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    :disabled {
        background-color: #f4f4f4;
        color: #b3b3b3;
    }
`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const ContainerConfig = styled.div`
    display: flex;
`;

export const ContainerConfigLeft = styled.div<ContainerConfigProps>`
    width: 100%;
    height: calc(100vh - 100px);

    ${props => props.isSplited && css`
        width: 65%;
    `}
`;

export const ContainerConfigRight = styled.div<ContainerConfigProps>`
    width: 0%;
    display: none;

    ${props => props.isSplited && css`
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-left: 1px solid #eef2f8;
        overflow: auto;
        height: calc(100vh - 100px);
    `}
`;

export const ContainerAutomation = styled.div`
    width: 100%;    
    height: calc(100% - 0px);
    overflow: auto;
    
    background-image: radial-gradient(#0000005c 1%, white 10%);
    background-position: 0 0;
    background-size: 15px 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    z-index: -10;
`;

export const DialogResume = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  background-color: white;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleResume = styled.div`
    border: 1px solid #f23b5d;
    border-radius: 10px;
    padding: 5px 10px;
    position: absolute;
    top: -13px;
    left: 15px;
    background-color: #ffe9ec;
    font-size: 11px;
    color: #f23b5d;
    font-weight: 500;
    text-transform: uppercase;
    z-index: 6;
`;

export const ResumeDescription = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-align: center;
    padding: 15px;
    font-weight: 200;
`;

export const ResumeTip = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: gray;
    text-align: center;
`;

export const BlockAutomationEmpty = styled.button`
    width: 90%;
    border-radius: 10px;
    border: 1px dashed #bbbbbb;
    padding: 10px 20px;
    max-width: 600px;
    min-height: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f4f4f4;
    cursor: pointer;    
    z-index: 1;

    :hover {
        opacity: 0.7;
    }
`;

export const BlockAutomationEmptyLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 20px;
    color: gray;

    svg {
        font-size: 20px;
    }
`;

export const BlockAutomationEmptyRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
        font-size: 12px;
        color: #202020cc;
    }

    h3 {
        font-size: 15px;
        font-weight: 500;
        margin-top: 3px;
        color: #202020cc;
    }
`;

export const BlockConnection = styled.div`
    height: 50px;
    min-height: 50px;
    width: 2px;
    background: linear-gradient(145deg, rgb(255 140 47) 25%, rgb(251 50 92) 50%, rgb(147 55 237) 75%);
`;

export const BlockAutomation = styled.div<BlockAutomationProps>`    
    width: 90%;
    border-radius: 10px;
    border: 1px solid #bbbbbb;
    max-width: 600px;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    padding: 10px 20px;
    background-color: white;
    z-index: 1;
    position: relative;    

    :hover {
        opacity: 0.9;
        border-width: 2px;
    }

    ${props => props.color && css`
        border-color: ${props.color+'70'};
    `}

    ${props => props.isSelected && css`
        border-width: 2px;
        border-color: ${props.color};
    `}
`;

export const BlockAutomationHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    height: 100%;      
`;

export const BlockAutomationLeft = styled.div<BlockAutomationLeftProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 24px;    
    padding: 2px;
    height: 45px;
    width: 45px;
    border-radius: 10px;
    
    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        font-size: 20px;
        color: white;
    }

    ${props => props.color && css`        
        background-color: ${props.color};
    `}

`;

export const BlockAutomationRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
        font-size: 12px;
        color: #202020cc;
    }

    h3 {
        font-size: 15px;
        font-weight: 500;
        margin-top: 3px;
        color: #202020cc;
    }
`;

export const BlockAutomationAction = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;    
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    position: absolute;
    right: 15px;
    top: 18px;
    margin-right: 0px !important;
    color: #bbbbbb;
    z-index: 99999999;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;                
        border-radius: 5px;

        svg {
            color: gray;
        }
    }
`;

export const BlockAutomationBody = styled.div`

`;

export const BlockConditionalAction = styled.div<BlockConditionalActionProps>`
    border-radius: 10px;
    border: 1px solid #bbbbbb;    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    max-width: 640px;

    background-color: white;
    z-index: 1;
    position: relative;    

    border-color: #f4f4f4;
    background-color: white;

    ${props => props.color && css`          
        border-color: ${props.color+'70'};
    `}

    ${props => props.isSelected && css`
        border: 2px solid #bbbbbb !important;
        border-width: 2px;
        border-color: ${props.color};
    `}

    ${props => !props.isSelected && css`
        :hover {
            border-bottom: 1px solid #bbbbbb !important;
            opacity: 0.9;
            border-width: 2px;
        }
    `}
`;

export const BlockConditionalActionItem = styled.div`
    width: 100%;
    padding: 15px 5px;
    padding-bottom: 5px;
`;

export const BlockConditionalActionHeader = styled.div<BlockConditionalActionHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 15px;

    h3 {
        font-size: 15px;
        font-weight: 500;    
        color: #202020cc;
    }

    span {
        background-color: #00bcd4;        
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 5px;
        color: white;
        font-weight: 500;

        ${props => props.color && css`            
            background-color: ${props.color};
        `}
    }

`;

export const BlockConditionalActionDescription = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: #202020cc;
    margin-top: 7px;
    padding: 0px 15px;
`;

export const BlockConditionalActionBody = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    justify-content: flex-start;
`;

export const BlockConditionalActionDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eef2f8;
`;

export const BlockConditionalActionAddItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    padding: 10px;
    font-size: 13px;
    color: #202020cc;
    background-color: white;
    font-weight: 500;
    width: 100%;
    max-width: 640px;
    border: 1px solid #e0dfdf;

    svg {
        margin-right: 10px;
        font-size: 10px;
    }

    label {
        cursor: pointer;
    }

    :hover {
        cursor: pointer;
        color: #f23b5d;
    }
`;

export const BlockNewActionTrigger = styled.div`
    height: 3px;
    width: 100%;
    background-color: transparent;

    :hover {
        height: 35px;

        div.block-new-action {
            display: flex !important;
        }
    }
`;

export const BlockNewAction = styled.div`
    height: 30px;
    display: none;
    width: 100%;
    background-color: #00bcd41e;
    border-radius: 10px;    
    color: #00bcd4;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;    

    svg {
        font-size: 10px;
        margin-right: 5px;
    }
`;

export const BlockAutomationStatusConfig = styled.div`
    position: absolute;
    left: -60px;
    transform: translateY(-50%);
    top: 50%;
    background-color: #f23b5d;
    height: 40px;
    width: 40px;
    border: 2px solid #eef2f8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    color: white;
`;

export const BlockAutomationStatusConfigFixed = styled.div` 
    margin-right: 10px;
    margin-bottom: 5px;
    min-width: 40px;
    min-height: 40px;  
    background-color: #f23b5d;
    height: 40px;
    width: 40px;
    border: 2px solid #eef2f8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    color: white;
`;

export const ContainerBlockAutomation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;